import { HttpErrorResponse } from '@angular/common/http';
import { Component, Injector, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AuthenService } from '@core/authen/authen.service';
import { CustomFormService } from '@core/form/custom-form.service';
import { PeopleHttpService } from '@core/http/people-http.service';
import { CentralConfigService } from '@core/services/central-config.service';
import { SessionService } from '@core/session.service';
import { environment } from '@env/environment';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { CustomDateParser2Formatter } from '@shared/components/boostrap-datepicker/boostrap-datepicker.component';
import { transformToTagModaByKey } from '@shared/components/tag-field/tag-field.component';
import { TemplateName } from '@shared/constants/visibility-config.const';
import { EmailSettingPropertyName } from '@shared/enums/email-setting-property-name.enum';
import { EntityName } from '@shared/enums/entity-name.enum';
import { OrgsSearchMode } from '@shared/enums/org-search-mode.enum';
import { LeadCompany } from '@shared/interfaces/lead-company.interface';
import {
  PeopleInterface,
  PersonOrganisation,
} from '@shared/interfaces/people.interface';
import { FormUtils } from '@shared/utils/form-utils';
import { StringUtils } from '@shared/utils/string-utils';
import { BaseEntityEditComponent } from '@src/app/components/base/base-detail/base-entity-edit/base-entity-edit.component';
import { MULTISTRING_DEFAULT_PLACEHOLDER } from '@src/app/shared/constants/multi-string.const';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { CustomAtrributeValue } from '@src/app/shared/interfaces/attribute-description.interface';
import { UserInterface } from '@src/app/shared/interfaces/user.interface';
import { Observable } from 'rxjs';
import {
  filter,
  finalize,
  skipUntil,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { PersonOrganisationsComponent } from './person-organisations/person-organisations.component';

interface EmailSetting extends CustomAtrributeValue {
  isLoading?: boolean;
}

@Component({
  selector: 'app-people-edit',
  templateUrl: './people-edit.component.html',
  providers: [
    CustomFormService,
    PeopleHttpService,
    { provide: NgbDateParserFormatter, useClass: CustomDateParser2Formatter },
  ],
})
export class PeopleEditComponent
  extends BaseEntityEditComponent<PeopleInterface>
  implements OnInit, OnChanges
{
  markOrgFormTouched;
  isLinkedToExistingOrg: boolean;

  offerringPlaceholder = MULTISTRING_DEFAULT_PLACEHOLDER;

  vCardImg$: Observable<string>;

  emailSettings: EmailSetting[] = [
    {
      codeId: EmailSettingPropertyName.ReceiveWeeklyActivitySummaryEmail,
      value: true,
      isLoading: false,
    },
    {
      codeId: EmailSettingPropertyName.ReceiveNewsEmail,
      value: true,
      isLoading: false,
    },
    {
      codeId: EmailSettingPropertyName.ReceiveMessageEmail,
      value: true,
      isLoading: false,
    },
    {
      codeId: EmailSettingPropertyName.IsCCMyselfSetting,
      value: true,
      isLoading: false,
    },
  ];

  private centralConfig: CentralConfigService;

  constructor(
    public peopleHttpService: PeopleHttpService,
    protected authenService: AuthenService,
    protected sessionService: SessionService,
    protected injector: Injector
  ) {
    super(peopleHttpService, authenService, sessionService, injector);

    this.entityName = EntityName.Person;
    this.orgsSearchMode = OrgsSearchMode.ForLeadPerson;
    this.centralConfig = this.injector.get(CentralConfigService);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.listenFilterChanged();
  }

  getRequiredKeys(): string[] {
    return ['FirstName', 'LastName'];
  }

  protected afterFormChangedStatus(): void {
    this.isOwner = this.entity?.id === this.profile?.id;
    if (!this.entity) return;
    const { leadCompany } = this.entity;

    if (leadCompany?.logo?.id && !leadCompany.logoId) {
      leadCompany.logoId = leadCompany.logo.id;
    }
  }

  customInit(): void {
    this.entityRoot = environment.jipUrl.people;
    this.formSections = [
      {
        fieldsName: ['PersonalMoodboards', 'InnovationMoodboards'],
      },
      {
        fieldsName: [
          'Image',
          'FirstName',
          'MiddleName',
          'LastName',
          'JobTitle',
          'Phone',
          'DateOfBirth',
          'Gender',
          'Location',
          'SocialMedias',
        ],
      },
      {
        fieldsName: ['MotivationStatement'],
      },
      {
        fieldsName: [
          'PersonalOfferings',
          'PersonalSearchings',
          'CompanyOfferings',
          'CompanySearchings',
        ],
      },
      {
        fieldsName: ['Education'],
      },
      {
        fieldsName: ['Organizations', 'LeadCompany', 'CompanySize'],
      },
      {
        fieldsName: ['IsPrivate'],
      },
    ];
  }

  hasEditPermision(profile, entity): boolean {
    return profile && entity && profile.id === entity.id;
  }

  isInitiator(profile, entity): boolean {
    return profile && entity && profile.id === entity.id;
  }

  getFormFieldValueFromEntity(name): any {
    switch (name) {
      case 'PersonalOfferings':
      case 'PersonalSearchings':
      case 'CompanyOfferings':
      case 'CompanySearchings':
        return this.getCustomTagData(name);
      default:
        return super.getFormFieldValueFromEntity(name);
    }
  }

  getDtoField(name, force = false): any {
    switch (name) {
      case 'PersonalOfferings':
      case 'PersonalSearchings':
      case 'CompanyOfferings':
      case 'CompanySearchings':
        return this.getCustomDto(name);
      case 'Organizations':
        return this.getMyOtherOrgsDto(name);
      case 'Image':
        return this.getImageDto();
      default:
        return super.getDtoField(name, force);
    }
  }

  saveSection(...index: number[]): Observable<boolean> {
    return super.saveSection(...index).pipe(
      tap((success) => {
        if (success) {
          this.authenService.updateProfileInfo();
        }
      })
    );
  }

  updateFormValue(): void {
    super.updateFormValue();
    this.mapCustomTags('PersonalOfferings');
    this.mapCustomTags('PersonalSearchings');
    this.mapCustomTags('CompanyOfferings');
    this.mapCustomTags('CompanySearchings');

    if (this.entity) {
      this.emailSettings.forEach((emailSetting: EmailSetting) => {
        const defaultEmailSettingValue = this.getFieldOptions(
          emailSetting.codeId
        )?.defaultValue;
        const emailSettingValue =
          this.entity[
            StringUtils.toLowerCaseFirstLetter(emailSetting.codeId)
          ] ?? defaultEmailSettingValue;
        emailSetting.value = emailSettingValue;
      });

      const isBot = FormUtils.getFieldValue(
        this.entity.attributeValues,
        'IsBot'
      )?.describedValue.value;

      this.form.patchValue({ IsBot: isBot });
    }

    if (this.entity?.id) {
      this.isLinkedToExistingOrg = !!this.entity?.leadCompany?.id;
    }

    this.profile$
      .pipe(untilDestroyed(this))
      .subscribe((profile: UserInterface) => {
        this.entity?.organizations?.forEach((item: PersonOrganisation) => {
          item.isOwner = BaseEntityEditComponent.hasPermission(profile, item);
        });
      });
  }

  formatField(dto: any, key: string): void {
    if (key === 'leadCompany') {
      const leadCompany: LeadCompany = dto[key];
      if (leadCompany) {
        dto[key] = {
          ...leadCompany,
          link: StringUtils.getCorrectUrl(leadCompany.link),
          logo: leadCompany.logo?.url,
        };
      }
    } else {
      super.formatField(dto, key);
    }
  }

  mapCustomTags(controlName: string): void {
    if (!this.form) {
      return;
    }
    const controlValue = this.getCustomTagData(controlName);
    this.form.patchValue({ [controlName]: controlValue });
  }

  getCustomTagData(name: string): any[] {
    const items = this.entity[StringUtils.toLowerCaseFirstLetter(name)];
    if (!items) {
      return [];
    }

    return transformToTagModaByKey(items, !this.editable, 'value', 'value');
  }

  getMyOtherOrgsDto(controlName: string): any {
    const items = this.form.getRawValue()[controlName] as any[];

    return {
      organizations: items.map((item) => {
        return item?.id ? item?.id : item;
      }),
    };
  }

  getCustomDto(name: string): any {
    if (this.form.getRawValue()[name]) {
      return {
        [StringUtils.toLowerCaseFirstLetter(name)]: this.form
          .getRawValue()
          [name].map((item: UntypedFormControl, index: number) => {
            return { value: item.value, order: index };
          }),
      };
    }
    return null;
  }

  markOrgFormAsTouched(): void {
    this.markOrgFormTouched = true;
  }

  updateForm(orgFormValue: LeadCompany): void {
    this.form.patchValue({
      LeadCompany: orgFormValue,
    });

    if (orgFormValue) {
      FormUtils.markControlAsTouch(this.form, 'LeadCompany');
    }
  }

  updateLeadOrgFormError(invalid: boolean): void {
    this.form.get('LeadCompany').setErrors({
      incorrect: true,
    });
    FormUtils.markControlAsTouch(this.form, 'LeadCompany');
  }

  updateOrganisationPayload(values: number[]): void {
    this.form.patchValue({
      Organizations: values,
    });
  }

  toggleExistingOrgLinking(isLinked: boolean): void {
    if (isLinked) {
      return;
    }

    const defaultValue =
      this.getFieldOptions('CompanySize')?.defaultValue?.value;
    this.form.get('CompanySize').setValue(defaultValue);
  }

  get searchIgnoredLeadOrgIds(): number[] {
    return this.entity?.leadCompany?.id ? [this.entity?.leadCompany?.id] : [];
  }

  reverseOtherOrgs(personOrgsComponent: PersonOrganisationsComponent): void {
    if (!personOrgsComponent) {
      return;
    }

    this.entity.organizations = [...personOrgsComponent.data];
  }

  reverseLinkToExistingOrg(): void {
    this.isLinkedToExistingOrg = !!this.entity?.leadCompany?.id;
  }

  handleAvatarChanged(imgUrl: string, isFirstChanged: boolean): void {
    if (!!imgUrl && !isFirstChanged) {
      this.authenService.updateProfileInfo();
    }
  }

  updatePeoplePrivacy(): void {
    if (!this.form) {
      return;
    }

    const isPrivate = this.form.get('IsPrivate')?.value;
    if (isPrivate !== null && isPrivate !== undefined) {
      if (isPrivate) {
        this.baseHttpService
          .unpublish(this.id)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.entity.isPrivate = true;
            this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
          });
      } else {
        this.baseHttpService
          .publish(this.id)
          .pipe(untilDestroyed(this))
          .subscribe(() => {
            this.entity.isPrivate = false;
            this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
          });
      }
    }
  }

  protected handleSyncEntityStateCount(): void {
    this.syncFollowingsState();
  }

  onChangeEmailSetting(emailSetting: EmailSetting): void {
    if (this.entity && this.form) {
      this.emailSettings.forEach((email) => {
        email.isLoading = true;
      });
      const emailSettingDto: any = {};
      emailSettingDto[StringUtils.toLowerCaseFirstLetter(emailSetting.codeId)] =
        emailSetting.value;
      this.peopleHttpService
        .patch(this.entity.id, emailSettingDto)
        .pipe(
          untilDestroyed(this),
          finalize(() => {
            this.emailSettings.forEach((email) => {
              email.isLoading = false;
            });
          })
        )
        .subscribe({
          next: () => {
            this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
          },
          error: (errorRes: HttpErrorResponse) => {
            if (errorRes.error?.title) {
              this.toastService.showError(errorRes.error.title);
            }
          },
        });
    }
  }

  getVCard(): void {
    this.vCardImg$ = this.profile$.pipe(
      filter(Boolean),
      switchMap(() =>
        this.peopleHttpService.getVCard(this.entity.id).pipe(startWith(''))
      )
    );
  }

  shouldShowDeactivatedWarning(leadCompany: LeadCompany): boolean {
    return leadCompany ? !leadCompany.isActive : false;
  }

  private listenFilterChanged(): void {
    const filterConditions = (template: TemplateName) =>
      this.entity?.id && template !== TemplateName.Default;

    this.filterStoredService
      .getCurrentFilterToken()
      .pipe(
        skipUntil(this.globalLoadingService.getLoadingState()),
        switchMap(() => this.centralConfig.uiTemplateConfig$),
        filter(filterConditions),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.handleLoading(true);

        this.getLatestEntity(this.entity.id);
      });
  }

  getQrCode(): Observable<string> {
    return this.peopleHttpService.getVCard(this.entity.id);
  }

  onBotChange(): void {
    const value = this.form.get('IsBot')?.value;

    this.peopleHttpService
      .patch(this.entity.id, { isBot: value } as unknown as PeopleInterface)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.toastService.showSuccess('UI.Toast.SavedSuccessfully');
        },
        error: (errorRes: HttpErrorResponse) => {
          if (errorRes.error?.title) {
            this.toastService.showError(errorRes.error.title);
          }
        },
      });
    }
    
  showLeadOrgError(): boolean {
    const leadCompany = this.form?.controls?.LeadCompany;
    const leadCompanyAttribute = this.getFieldOptions('LeadCompany');

    if (!leadCompanyAttribute.required) {
      return false;
    }

    if (!leadCompany || !(leadCompany.touched || leadCompany.dirty)) {
      return false;
    }

    if (this.isLinkedToExistingOrg) {
      return !leadCompany.value?.id;
    }


    return leadCompanyAttribute.required && !leadCompany.value;
  }
}
