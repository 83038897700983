import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-enforce-profile-dialog',
  templateUrl: './enforce-profile-dialog.component.html',
})
export class EnforceProfileDialogComponent {
  @Input() id: number;

  constructor(protected activeModal: NgbActiveModal) {}
}
