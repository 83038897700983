<ng-container *ngIf="data$ | async as response; else skeleton">
  <ng-container *ngIf="response.items?.length; else emptyContent">
    <div
      class="list-tiles"
      [class.two-columns]="columns === '2'"
      [class.widget__card--list--background]="!bg_color"
      [style.background-color]="bg_color"
      *ngIf="!isLoading; else skeleton"
    >
      <div
        (click)="goToEntityDetails(person.id)"
        *ngFor="let person of response.items"
        class="list-tiles__item"
      >
        <app-card-layout [isSquareImage]="true">
          <!--        Image-->
          <ng-container slot="img">
            <app-image-wrapper
              [wrapperClasses]="
                'widget__image-wrapper entity__image-wrapper h-100'
              "
              [imgObject]="person.image || avatar"
              [placeholderImg]="avatar"
              [isVCardShown]="isLogin"
              [titleTemplateRef]="qrCodeTitle"
              [getEntityDataCallback]="getVcard.bind(this, person)"
            >
              <ng-template #qrCodeTitle>
                <div class="text-center">
                  <h3 class="page-header__text widget__heading" translate>
                    UI.Profile.MemberCard
                  </h3>
                  <span class="venture__label widget__label m-0" translate
                    >UI.Profile.MemberId</span
                  >
                  <span class="widget__content">
                    {{ person.id }}
                  </span>
                </div>
              </ng-template>
            </app-image-wrapper>
          </ng-container>

          <!--        Content-->
          <ng-container slot="body">
            <div
              class="simple-card__title people__name widget__heading mt-1 mt-md-2 mt-xl-3 d-flex align-items-center"
            >
              <span
                data-placement="top"
                appTooltip
                triggers="manual"
                #t="ngbTooltip"
                [toolTipControl]="t"
                [ngbTooltip]="person.firstName + ' ' + person.lastName"
                class="truncate-line-1"
              >
                {{ person.firstName + ' ' + person.lastName }}
              </span>
              <app-bot-flag
                *ngIf="person.isBot"
                [parentClass]="'ml-1'"
                style="flex-shrink: 0; height: 70%"
              ></app-bot-flag>
            </div>
            <div class="people__label widget__label mt-1 mt-md-2 mt-xl-3">
              {{ 'UI.People.JobTitle' | translate }}
            </div>
            <div class="people__info">
              <span
                data-placement="top"
                appTooltip
                triggers="manual"
                #t2="ngbTooltip"
                [toolTipControl]="t2"
                [ngbTooltip]="person.jobTitle"
                class="truncate-line-1"
              >
                {{ person.jobTitle }}
              </span>
            </div>
            <div class="people__label widget__label mt-1" translate>
              Company name
            </div>
            <div class="people__info">
              <span
                data-placement="top"
                appTooltip
                triggers="manual"
                #t3="ngbTooltip"
                [toolTipControl]="t3"
                [ngbTooltip]="person.company?.displayText"
                class="truncate-line-1"
              >
                {{ person.company?.name }}
              </span>
            </div>
          </ng-container>
        </app-card-layout>
      </div>
    </div>
    <app-bootstrap-pagination
      *ngIf="show_paginator"
      (changePage)="searchEntitiesService.changePaging($event)"
      [page]="searchEntitiesService.filterDTO.pageIndex"
      [pageSize]="searchEntitiesService.filterDTO.pageSize"
      [collectionSize]="response.rowCount"
    ></app-bootstrap-pagination>
  </ng-container>
</ng-container>
<ng-template #emptyContent>
  <app-empty-content></app-empty-content>
</ng-template>
<ng-template #skeleton>
  <div class="loading-skeleton">
    <div class="row">
      <div
        *ngFor="let item of skeletonCount"
        class="col-12"
        [ngClass]="'col-sm-' + 12 / skeletonCount.length"
      >
        <div class="card">
          <div class="skeleton-item img-skeleton"></div>
          <div class="card-body">
            <h5 class="card-title mt-4">Card title</h5>
            <h6>Card title</h6>
            <h6>Card title</h6>
            <h6>Card title</h6>
            <h6>Card title</h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
