import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { EmojiData } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { FileUploadComponent } from '@shared/components/file-upload/file-upload.component';
import { ImageInputDisplayComponent } from '@shared/components/molecules/image-input-display/image-input-display.component';
import {
  AttributeType,
  PatternType,
  SelectionType,
  UIControlId,
  UIHelpTextPosition,
} from '@shared/enums/attribute-type.enum';
import {
  CustomAtrributeValue,
  LinkItem,
} from '@shared/interfaces/attribute-description.interface';
import { FileInterface } from '@shared/interfaces/file.interface';
import { LanguageInterface } from '@shared/interfaces/language.interface';
import { FormUtils } from '@shared/utils/form-utils';
import {
  DependantSelectionService,
  SelectedChoice,
} from '@src/app/core/form/dependant-selection.service';
import { BaseHttpService } from '@src/app/core/http/base-http.service';
import { DropdownItemInterface } from '@src/app/shared/components/boostrap-dropdown/boostrap-dropdown.component';
import { untilDestroyed } from '@src/app/shared/functions/until-destroyed';
import { AttributeDescription } from '@src/app/shared/interfaces/attribute-description.interface';
import { Entity } from '@src/app/shared/models/entity.model';
import { UniqueNameValidator } from '@src/app/shared/utils/form-fields-validation';
import { StringUtils } from '@src/app/shared/utils/string-utils';
import { ContentChange } from 'ngx-quill';
@Component({
  selector: 'app-form-field-custom',
  templateUrl: './form-field-custom.component.html',
})
export class FormFieldCustomComponent implements OnChanges, OnDestroy {
  @Input() formInput: UntypedFormGroup = new UntypedFormGroup({});
  @Input() controlName: string;
  @Input() attributeDescription: AttributeDescription;
  @Input() selectionChoices: CustomAtrributeValue[];

  @Input() type: string;

  @Input() showLabel = false;
  @Input() labelClass = '';

  @Input() editable = true;
  @Input() selectedIndex = 0;
  @Input() currentValue;
  @Input() hideLink = false;
  @Input() shouldValidateOnTouch = false;
  @Input() styleClass = '';
  @Input() datepickerPlacement: PlacementArray;

  @Input() allowCropImg = false;
  @Input() aspectRatio = 1;

  @Input() showLanguageIndicator = false;

  @Input() language: string;

  @Input() options: {
    id?: number;
    placeholder?: string;
    defaultLabel?: string;
    dropdownItems?: DropdownItemInterface[];
    defaultItem?: CustomAtrributeValue;
    pastTime?: boolean;
    futureTime?: boolean;
    isViewAsText?: boolean;
    hideTimeOfDate?: boolean;
    shouldUseDefaultDate?: boolean;
    replaceAsStarKey?: string;
    enabledAddNew?: boolean;
    fixedItems?: LinkItem[];
    areRowsLimited?: boolean;
    isWideView?: boolean;
    showHiddenMarkBackground?: boolean;
    isOptional?: boolean;
    elementId?: string;

    showLanguageIndicator?: boolean;
    showLanguageSwitcher?: boolean;

    // TODO: Need Refactor -> Handle for custom attribute section first, apply for all form later
    readonly?: boolean;
    // TODO: Need Refactor -> Should use SystemType of attribute type Image, remember to check with DocumentType
    multiLocale?: boolean;
    allowEmojiOnRTE?: boolean;
    allowImgOnRTE?: boolean;
    hideQuillToolbar?: boolean;
    preventDisableControl?: boolean;
    isReplacedByStar?: boolean;
    allowCropImg?: boolean;
    aspectRatio?: number;
    smartTyping?: boolean;
    checkDuplicatedName?: boolean;
  } = {};

  @Input() customUploadButtonTemplate: TemplateRef<void>;

  @Input() displayFileLoading = true;

  @Input() disabled = false;

  @Input() httpService: BaseHttpService<Entity>;

  @Output() valueChange = new EventEmitter<any>();

  @Output() languageSelected = new EventEmitter<LanguageInterface>();

  @Output() languageRemoved = new EventEmitter<string>();

  @Output() storedValue = new EventEmitter();

  @ViewChild('imageInputDisplay') imageInputDisplay: ImageInputDisplayComponent;

  @ViewChild('fileUpload') fileUpload: FileUploadComponent;

  @ViewChild('imageUpload') imageUpload: FileUploadComponent;

  editor: any;

  allType = AttributeType;
  UIControlId = UIControlId;
  UIHelpTextPosition = UIHelpTextPosition;
  value = [];

  richText = {
    required: false,
    maxlength: false,
  };

  passwordIsShow = false;
  isInputFocused = false;
  customInvalidCondition = true;
  initialValue: any;
  SelectionType = SelectionType;

  constructor(private dependantSelectionService: DependantSelectionService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.attributeDescription &&
      JSON.stringify(changes.attributeDescription.previousValue) ===
        JSON.stringify(changes.attributeDescription.currentValue)
    ) {
      return;
    }
    if (
      changes.attributeDescription ||
      changes.formInput ||
      changes.controlName ||
      changes.type
    ) {
      if (this.attributeDescription) {
        this.generateOptions();
        this.handleInputChange();
      }
    }

    if (changes.editable && this.formControl) {
      if (this.editable) {
        this.formControl.enable();
      } else {
        if (
          !this.options.preventDisableControl &&
          this.attributeDescription.type !== PatternType.Dedicated
        ) {
          this.formControl.disable();
        }
      }

      this.onSMTPPasswordEditToggle();
    }
    if (changes.currentValue) {
      this.updateCurrentValue();
    }

    if (changes.attributeDescription) {
      this.dependantSelectionService
        .getSelectedChoices()
        .pipe(untilDestroyed(this))
        .subscribe((choices: SelectedChoice[]) => {
          const masterChoiceId =
            this.attributeDescription?.choice?.masterCodeId;
          if (!masterChoiceId) return;

          const masterChoice = choices.find(
            (i) => i.propertyName === masterChoiceId
          );

          if (!masterChoice) return;

          const filteredChoices =
            this.attributeDescription.choice.selections.filter(
              (c) => c.masterCodeId === masterChoice?.value.codeId
            );
          this.selectionChoices = [
            this.attributeDescription.choice.selections[0],
            ...filteredChoices,
          ];
        });
    }

    if (
      changes.options &&
      changes.httpService &&
      changes.formInput &&
      changes.attributeDescription
    ) {
      this.registerCheckDuplicatedNameValidator();
    }
  }

  updateCurrentValue(): void {
    if (this.currentValue) {
      this.changeSelectedIndex(this.currentValue);
    }
  }
  get formControl(): AbstractControl {
    if (this.formInput) {
      return this.formInput.controls[this.controlName];
    }
  }

  get isInvalid(): boolean {
    return (
      this.formControl &&
      this.formControl.invalid &&
      (!this.shouldValidateOnTouch ||
        this.formControl.dirty ||
        this.formControl.touched) &&
      this.isManageError()
    );
  }

  isManageError(): boolean {
    return (
      this.formControl.hasError('required') ||
      this.formControl.hasError('maxlength') ||
      this.formControl.hasError('integerOnly') ||
      this.formControl.hasError('pattern') ||
      this.formControl.hasError('notEmptyOrWhitespace') ||
      this.formControl.hasError('duplicate') ||
      this.formControl.hasError('nameDuplicated')
    );
  }

  isViewAsText(): boolean {
    return (
      this.options.isViewAsText ||
      this.type === this.allType.Link ||
      this.type === this.allType.StringSingle ||
      this.type === this.allType.String ||
      this.type === this.allType.PhoneNumber ||
      this.type === this.allType.Integer ||
      this.type === this.allType.Double ||
      this.type === this.allType.SingleSelection
    );
  }

  requireFormatting(): boolean {
    return (
      this.type === this.allType.Link ||
      this.type === this.allType.SingleSelection ||
      this.type === this.allType.DateTime ||
      this.type === this.allType.RTE
    );
  }

  generateOptions(): void {
    if (!this.attributeDescription) {
      return;
    }
    this.controlName =
      this.controlName || this.attributeDescription.propertyName;
    if (!this.type) {
      this.type = this.attributeDescription.isHtml
        ? this.allType.RTE
        : this.attributeDescription.attributeType;
    }

    let defaultValue = this.attributeDescription.defaultValue;

    const customAttributes = this.attributeDescription.choice;

    switch (this.type) {
      case AttributeType.String:
        break;
      case AttributeType.SingleSelection:
        if (customAttributes && customAttributes.selections) {
          this.selectionChoices = [...customAttributes.selections]
            .sort((a, b) => {
              return a.order - b.order;
            })
            .map((item) => {
              return {
                ...item,
                group: '',
              };
            });

          const frequentlyChoices =
            this.attributeDescription.frequentlyUsedChoice?.map((item) => {
              return { ...item, group: 'Frequently used choices' };
            }) || [];

          this.selectionChoices = [
            ...frequentlyChoices,
            ...this.selectionChoices,
          ];
        }
        if (defaultValue) {
          this.options.defaultItem = defaultValue;
        }

        break;
      case AttributeType.MultipleSelection:
      case AttributeType.VenturePhase:
      case AttributeType.VentureStatus:
        if (customAttributes && customAttributes.selections) {
          this.selectionChoices = [...customAttributes.selections].sort(
            (a, b) => {
              return a.order - b.order;
            }
          );
        }
        if (defaultValue) {
          this.options.defaultItem = defaultValue;
        }
        break;
      case AttributeType.MultiString:
        if (Array.isArray(defaultValue)) {
          defaultValue = [];
        }
        break;
      case AttributeType.RelatedEntity:
        defaultValue = this.attributeDescription.relatedEntityDefaultValues;
        break;
      case AttributeType.RTE:
        if (this.options?.areRowsLimited === undefined) {
          this.options.areRowsLimited = true;
        }
        break;
    }
    this.updateDefaultValue(defaultValue);
  }

  updateDefaultValue(defaultValue): void {
    if (this.formControl?.value) {
      return;
    }
    this.setValue(defaultValue, false);
  }

  handleInputChange(): void {
    if (!this.formInput) {
      return;
    }

    const control = this.formInput.controls[this.controlName];

    this.updateCurrentValue();

    if (!control) {
      return;
    }

    control.valueChanges.subscribe((value) => {
      this.changeSelectedIndex(value);
      this.valueChange.emit(value);
    });
  }

  changeSelectedIndex(value): void {
    if (!value || !this.selectionChoices) {
      return;
    }

    this.selectionChoices.forEach((item, index) => {
      if (typeof value === 'string') {
        if (
          value === item.order ||
          value === item.codeId ||
          value === item.value
        ) {
          this.selectedIndex = index;
        }
      } else if (typeof value === 'object') {
        if (value.codeId === item.codeId) {
          this.selectedIndex = index;
        }
      }
    });

    if(!this.attributeDescription) return;
    const choice = this.selectionChoices[this.selectedIndex];
    this.dependantSelectionService.updateSelectedChoice({
      propertyName: this.attributeDescription.propertyName,
      value: choice,
    });
  }

  setValue(value, shouldEmitEvent = true): void {
    if (this.formInput && this.controlName && this.editable) {
      this.formInput.patchValue(
        { [this.controlName]: value },
        { emitEvent: shouldEmitEvent }
      );

      if (shouldEmitEvent) {
        this.formInput.markAsDirty();
      }
    }

    if (this.type === this.allType.RTE) {
      this.onRTEContentChanged(value);
    }

    if (this.type === AttributeType.SingleSelection) {
      const { propertyName } = this.attributeDescription;
      this.dependantSelectionService.updateSelectedChoice({
        propertyName,
        value,
      });

      this.dependantSelectionService.updateMasterChoiceId(propertyName);
    }
  }

  onRTEContentChanged(event: ContentChange): void {
    const { text } = event;

    this.richText.required =
      !text || (text.trim() === '' && this.attributeDescription.required);

    this.richText.maxlength = StringUtils.isRTEContentMaxlengthError(
      text,
      this.attributeDescription.maximumLength
    );

    if (this.richText.required) {
      this.formControl?.setErrors({ required: true });
    } else {
      FormUtils.removeError(this.formControl, 'required');
    }

    if (this.richText.maxlength) {
      this.formControl.setErrors({ maxlength: true });
    } else {
      FormUtils.removeError(this.formControl, 'maxlength');
    }
  }

  get elementId(): string {
    return this.options?.elementId || this.controlName;
  }

  get readonly(): boolean {
    // Need refactor: Remove readonly from options and use attributeDescription.readonly
    return this.options?.readonly;
  }

  getStringTypePlaceholder(): string {
    return (
      this.options.placeholder ||
      (this.attributeDescription.helpTextId?.includes(
        UIHelpTextPosition.Placeholder
      )
        ? this.attributeDescription.helpTextId
        : '')
    );
  }

  onEmojisClicked(e: EmojiData): void {
    const cursorPosition = this.editor.selection.savedRange.index;

    this.editor.insertText(cursorPosition, e.native, 'user');

    setTimeout(() => {
      this.editor.setSelection(cursorPosition + 2, 0);
    }, 0);
  }

  getEditorInstance(event: any): void {
    this.editor = event;
  }

  isStringLink(content: unknown): boolean {
    return StringUtils.isString(content) || !content;
  }

  onLanguageSelected(event: LanguageInterface): void {
    this.languageSelected.emit(event);
  }

  onLanguageRemoved(locale: string): void {
    this.languageRemoved.emit(locale);
  }

  uploadChange(image: FileInterface): void {
    this.formInput.get('imageId')?.setValue(image.id);
  }

  clearMessageImage(): void {
    this.formInput.get('imageId').setValue(null);
  }

  onRTEPasted(event: ClipboardEvent): void {
    if (!this.options.allowImgOnRTE) return;

    if (!event.clipboardData?.files?.length) return;

    this.fileUpload?.onSelectFile({
      target: { files: event.clipboardData.files },
    });
  }

  onSMTPPasswordEditToggle() {
    const smtpPasswordControl = this.formInput.get('EmailSMTPPassword');
    if (!smtpPasswordControl) return;
    if (this.editable) {
      const value = smtpPasswordControl.value;
      this.storedValue.emit(value);
      this.formInput.get('EmailSMTPPassword')?.setValue('');
    }
  }

  togglePassword(event: Event): void {
    event.preventDefault();
    this.passwordIsShow = !this.passwordIsShow;
  }

  onInputFocus() {
    this.isInputFocused = true;
  }

  onInputBlur() {
    this.isInputFocused = false;
  }

  registerCheckDuplicatedNameValidator() {
    const propertyName = this.attributeDescription.propertyName;
    this.initialValue = this.formInput.controls[propertyName].value;
    if (this.options.checkDuplicatedName && this.httpService) {
      this.formInput.controls[propertyName].setAsyncValidators(
        UniqueNameValidator.validateDuplicateNameFromApi(
          this.httpService,
          this.initialValue
        )
      );
    }
  }

  ngOnDestroy(): void {
    this.formControl?.clearAsyncValidators();
  }
}
