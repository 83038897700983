<app-unsaved-form-check
  [unsavedFormCheckService]="unsavedFormCheckService"
></app-unsaved-form-check>

<ng-container *ngIf="!isLoadingEntity && !isGoingToOtherPage">
  <form *ngIf="form" [formGroup]="form" class="person venture-edit">
    <div class="venture__wrapper venture-details person-detail-conainer">
      <ng-template #personalInfo>
        <div
          class="venture__label widget__label"
          [class]="sectionPersonInfo1?.editting ? 'venture__label--head' : ''"
          translate
        >
          Personal Information
        </div>

        <div
          *ngIf="!sectionPersonInfo1?.editting"
          class="widget__content venture__email w-100"
        >
          {{ this.entity?.user?.email }}
        </div>

        <div class="row">
          <div [class.col-sm-4]="sectionPersonInfo1?.editting" class="col-12">
            <app-form-field-custom
              [attributeDescription]="getFieldOptions('Phone')"
              [editable]="
                isCreating || (editable && sectionPersonInfo1?.editting)
              "
              [formInput]="form"
              [showLabel]="
                isCreating || (editable && sectionPersonInfo1?.editting)
              "
              [type]="allType.PhoneNumber"
            >
            </app-form-field-custom>
          </div>

          <div [class.col-sm-4]="sectionPersonInfo1?.editting" class="col-12">
            <app-form-field-custom
              *ngIf="
                form?.getRawValue().DateOfBirth || sectionPersonInfo1.editting
              "
              [attributeDescription]="getFieldOptions('DateOfBirth')"
              [editable]="
                isCreating || (editable && sectionPersonInfo1?.editting)
              "
              [formInput]="form"
              [options]="{ pastTime: true, hideTimeOfDate: true }"
              [showLabel]="
                isCreating || (editable && sectionPersonInfo1?.editting)
              "
              [datepickerPlacement]="'bottom-right'"
            ></app-form-field-custom>
          </div>

          <div [class.col-sm-4]="sectionPersonInfo1?.editting" class="col-12">
            <app-form-field-custom
              [attributeDescription]="getFieldOptions('Gender')"
              [editable]="
                isCreating || (editable && sectionPersonInfo1?.editting)
              "
              [formInput]="form"
              [options]="{ replaceAsStarKey: 'Other' }"
              [showLabel]="
                isCreating || (editable && sectionPersonInfo1?.editting)
              "
              styleClass="w-100"
            ></app-form-field-custom>
          </div>
        </div>

        <app-form-field-custom
          [formInput]="form"
          [type]="allType.StringSingle"
          [editable]="isCreating || (editable && sectionPersonInfo1?.editting)"
          [showLabel]="isCreating || (editable && sectionPersonInfo1?.editting)"
          [attributeDescription]="getFieldOptions('Location')"
        ></app-form-field-custom>

        <div
          *ngIf="isCreating || (editable && sectionPersonInfo1?.editting)"
          class="venture__label widget__label 'venture__label--head' mt-3"
          translate
        >
          Add Social Media Link
        </div>

        <app-form-field-custom
          *ngIf="isCreating || (editable && sectionPersonInfo1?.editting)"
          [type]="allType.SocialLink"
          [formInput]="form"
          [editable]="true"
          [attributeDescription]="getFieldOptions('SocialMedias')"
          [selectionChoices]="form?.getRawValue().SocialMedias"
        ></app-form-field-custom>

        <div *ngIf="!sectionPersonInfo1?.editting">
          <app-social-links [items]="entity?.socialMedias"></app-social-links>
        </div>
      </ng-template>

      <ng-template #moodboardRef>
        <app-form-section
          #section0
          [index]="0"
          [showEditButton]="false"
          [hideControls]="true"
          [editting]="sectionPersonInfo1?.editting"
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <ng-container *ngIf="section0.editting; else viewMoodboard">
            <app-form-field-custom
              [formInput]="form"
              [type]="allType.MoodboardGroup"
              [editable]="isCreating || (editable && section0?.editting)"
              [attributeDescription]="getFieldOptions('PersonalMoodboards')"
            ></app-form-field-custom>

            <app-form-field-custom
              [formInput]="form"
              [type]="allType.MoodboardGroup"
              [editable]="isCreating || (editable && section0?.editting)"
              [attributeDescription]="getFieldOptions('InnovationMoodboards')"
            ></app-form-field-custom>
          </ng-container>

          <ng-template #viewMoodboard>
            <app-moodboard
              [personalMoodboards]="form?.value?.PersonalMoodboards"
              [innovationMoodboards]="form?.value?.InnovationMoodboards"
            ></app-moodboard>
          </ng-template>
        </app-form-section>
      </ng-template>

      <div class="venture__body widget__card--background">
        <div class="row flex-column-reverse flex-md-row">
          <div class="col col-12 col-md-7 moodboard">
            <ng-container *ngTemplateOutlet="moodboardRef"></ng-container>
          </div>

          <div
            *ngIf="sectionPersonInfo1?.editting"
            class="col col-12 person__info2"
          >
            <app-form-section
              #sectionPersonInfo2
              [index]="1"
              [canSave]="isValidSection(1) && isValidSection(0)"
              [editting]="sectionPersonInfo1?.editting"
              [showEditButton]="false"
              [hideControls]="false"
              [saveSectionCallback]="saveSection.bind(this, 0)"
              (edittingChange)="sectionPersonInfo1.editting = $event"
              (cancelSection)="
                cancelSection($event); sectionPersonInfo1.cancel()
              "
              (editIndexChange)="markEditingSectionIndex($event)"
              [hasBindingDataBeforeSave]="true"
            >
              <ng-container *ngTemplateOutlet="personalInfo"></ng-container>
            </app-form-section>
          </div>

          <div class="col col-12 col-md-5">
            <app-form-section
              #sectionPersonInfo1
              [index]="1"
              [canSave]="isValidSection(1)"
              [showEditButton]="!isCreating && editable"
              [hideControls]="true"
              (editIndexChange)="markEditingSectionIndex($event)"
            >
              <div class="position-relative">
                <div
                  *ngIf="editable && sectionPersonInfo1?.editting"
                  [class.venture__image-upload--invalid]="
                    imageControl?.isInvalid
                  "
                  class="venture__image-upload bg-primary btn-primary"
                >
                  <app-form-field-custom
                    #imageControl
                    styleClass="fa-image text-white fill-container small-image-upload-btn"
                    [formInput]="form"
                    [allowCropImg]="true"
                    [attributeDescription]="getFieldOptions('Image')"
                    [options]="{
                      id: form?.value?.Image?.id,
                      multiLocale: isCreating
                    }"
                  >
                  </app-form-field-custom>
                </div>

                <app-image-wrapper
                  #imgWrapper
                  [wrapperClasses]="
                    'widget__image-wrapper profile-image-wrapper'
                  "
                  [imageclasses]="'widget__image'"
                  [imgObject]="form.value?.Image"
                  [imgAlt]="form.value?.title"
                  [isVCardShown]="isLogin"
                  [titleTemplateRef]="qrCodeTitle"
                  (imageChanged)="
                    handleAvatarChanged($event, imgWrapper.isFirstChanged)
                  "
                  [getEntityDataCallback]="getQrCode.bind(this)"
                >
                  <ng-template #qrCodeTitle>
                    <div class="text-center">
                      <h3 class="page-header__text widget__heading" translate>
                        UI.Profile.MemberCard
                      </h3>
                      <span class="venture__label widget__label m-0" translate
                        >UI.Profile.MemberId</span
                      >
                      <span class="widget__content">
                        {{ entity.id }}
                      </span>
                    </div>
                  </ng-template>
                </app-image-wrapper>
              </div>
              <div class="person__info widget__content">
                <div class="pl-3">
                  <div
                    *ngIf="!sectionPersonInfo1.editting; else editName"
                    class="people__name widget__heading mt-2 d-flex align-items-center mr-2"
                  >
                    <span
                      data-placement="top"
                      appTooltip
                      triggers="manual"
                      #t="ngbTooltip"
                      [toolTipControl]="t"
                      [ngbTooltip]="
                        form?.value.FirstName
                          | concatName : form?.value.LastName
                      "
                      class="truncate-line-1"
                      container="body"
                    >
                      {{
                        form?.value.FirstName
                          | concatName : form?.value.LastName
                      }}
                    </span>
                    <app-bot-flag
                      *ngIf="form?.value.IsBot"
                      [parentClass]="'ml-1'"
                    ></app-bot-flag>
                  </div>
                  <ng-template #editName>
                    <div class="my-2">
                      <app-form-field-custom
                        [formInput]="form"
                        [editable]="
                          isCreating ||
                          (editable && sectionPersonInfo1?.editting)
                        "
                        [showLabel]="true"
                        [type]="allType.StringSingle"
                        [attributeDescription]="getFieldOptions('FirstName')"
                      >
                      </app-form-field-custom>
                      <app-form-field-custom
                        [formInput]="form"
                        [editable]="
                          isCreating ||
                          (editable && sectionPersonInfo1?.editting)
                        "
                        [showLabel]="true"
                        [type]="allType.StringSingle"
                        [attributeDescription]="getFieldOptions('MiddleName')"
                      >
                      </app-form-field-custom>
                      <app-form-field-custom
                        [formInput]="form"
                        [editable]="
                          isCreating ||
                          (editable && sectionPersonInfo1?.editting)
                        "
                        [showLabel]="true"
                        [type]="allType.StringSingle"
                        [attributeDescription]="getFieldOptions('LastName')"
                      >
                      </app-form-field-custom>
                    </div>
                  </ng-template>
                  <div class="mt-2">
                    <app-form-field-custom
                      [formInput]="form"
                      [editable]="
                        isCreating || (editable && sectionPersonInfo1?.editting)
                      "
                      [showLabel]="true"
                      [type]="allType.StringSingle"
                      [attributeDescription]="getFieldOptions('JobTitle')"
                    >
                    </app-form-field-custom>
                  </div>
                  <div *ngIf="!sectionPersonInfo1?.editting" class="mt-2">
                    <ng-container
                      *ngTemplateOutlet="personalInfo"
                    ></ng-container>
                  </div>
                </div>

                <div class="people__footer mt-2">
                  <app-interaction-bar-people
                    [isSamePerson]="(profile$ | async)?.id === entity?.id"
                    [data]="entity"
                    [httpService]="baseHttpService"
                    [showDeactivateProfileBtn]="true"
                    (triggerToggleFavorite)="toggleFavorite()"
                  ></app-interaction-bar-people>
                </div>
              </div>
            </app-form-section>
          </div>
        </div>
      </div>

      <ngb-accordion [activeIds]="['privacy-panel']" *ngIf="isOwner">
        <ngb-panel
          cardClass="venture__body venture__accordion ignore-overflow"
          id="privacy-panel"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button class="btn panel__header" ngbPanelToggle type="button">
              <div translate class="pull-left float-left">
                <span class="venture__company-header d-block d-lg-inline-block">
                  {{ 'UI.Profile.PersonalSettings' | translate }}
                </span>
              </div>
              <i
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <div class="venture__body widget__card--background m-0">
              <div class="row">
                <div class="mb-3 col-12">
                  <h6 class="venture__label widget__label">
                    {{ getFieldOptions('IsPrivate')?.displayName | translate }}
                  </h6>
                </div>
                <div class="col-12 d-flex">
                  <div [formGroup]="form">
                    <div class="organization__radio-item">
                      <input
                        appRequireLogin
                        formControlName="IsPrivate"
                        name="IsPrivate"
                        id="isPublic"
                        type="radio"
                        class="organization__radio"
                        [value]="false"
                        (change)="updatePeoplePrivacy()"
                      />
                      <label
                        for="isPublic"
                        class="radio-info d-flex flex-column"
                      >
                        <span class="radio-text">
                          <i class="fas fa-globe-europe"></i>
                          {{ 'Public' | translate }}
                        </span>
                        <span class="radio-hint">
                          {{ 'UI.Profile.PublicRadioHint' | translate }}
                        </span>
                      </label>
                    </div>
                    <div class="organization__radio-item">
                      <input
                        appRequireLogin
                        formControlName="IsPrivate"
                        name="IsPrivate"
                        id="isPrivate"
                        class="organization__radio"
                        type="radio"
                        [value]="true"
                        (change)="updatePeoplePrivacy()"
                      />
                      <label
                        for="isPrivate"
                        class="radio-info d-flex flex-column"
                      >
                        <span class="radio-text">
                          <i class="fas fa-lock"></i>
                          {{ 'Private' | translate }}
                        </span>
                        <span class="radio-hint radio-hint--warning">
                          {{ 'UI.Profile.PrivateRadioHint' | translate }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h6 class="venture__label widget__label">E-Mail Settings</h6>
                </div>
                <div class="col-12">
                  <ng-container *ngFor="let emailSetting of emailSettings">
                    <div class="mb-3">
                      <div
                        class="venture__label widget__label--no-truncate venture__label--head"
                      >
                        {{ getFieldOptions(emailSetting.codeId)?.displayName }}
                      </div>
                      <div>
                        <app-toggle-button-slider
                          [(status)]="emailSetting.value"
                          [shouldRequiredLogin]="true"
                          [settings]="{
                            disabled: emailSetting.isLoading || !isOwner,
                            activeText: 'Enable' | translate,
                            deactiveText: 'Disable' | translate
                          }"
                          (statusChange)="onChangeEmailSetting(emailSetting)"
                        >
                        </app-toggle-button-slider>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h6 class="venture__label widget__label" translate>
                    UI.BotSetting
                  </h6>
                </div>
                <div class="col-12">
                  <div [formGroup]="form" class="form-group form-check">
                    <input
                      formControlName="IsBot"
                      name="IsBot"
                      type="checkbox"
                      class="form-check-input"
                      (change)="onBotChange()"
                    />
                    <label class="form-check-label" for="exampleCheck1">{{
                      getFieldOptions('IsBot')?.displayName
                    }}</label>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>

      <div class="venture__body widget__card--background">
        <app-form-section
          #section2
          [index]="2"
          [showEditButton]="!isCreating && editable"
          [canSave]="isValidSection(2)"
          [saveSectionCallback]="saveSection.bind(this)"
          (cancelSection)="cancelSection($event)"
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <div class="row">
            <div class="col col-12 person__motivation-statement">
              <app-form-field-custom
                [formInput]="form"
                [showLabel]="true"
                [showLanguageIndicator]="isCreating"
                [labelClass]="'venture__label--head'"
                [editable]="isCreating || (editable && section2.editting)"
                [attributeDescription]="getFieldOptions('MotivationStatement')"
                [options]="{ isViewAsText: true }"
              ></app-form-field-custom>
            </div>
          </div>
        </app-form-section>
      </div>

      <div class="venture__body widget__card--background">
        <app-form-section
          #section3
          [index]="3"
          [canSave]="isValidSection(3)"
          [showEditButton]="!isCreating && editable"
          [saveSectionCallback]="saveSection.bind(this)"
          (cancelSection)="cancelSection($event)"
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <div class="">
            <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
              <li ngbNavItem>
                <a ngbNavLink
                  ><span
                    [ngClass]="{
                      'venture__tabs__title--invalid':
                        editable && isFieldInvalid('Excerpt')
                    }"
                    class="venture__tabs__title"
                    translate
                    >Personal Offerings <span class="after"></span> </span
                ></a>
                <ng-template ngbNavContent>
                  <app-form-field-custom
                    [formInput]="form"
                    [editable]="isCreating || (editable && section3.editting)"
                    [type]="allType.MultiString"
                    [showLabel]="true"
                    [labelClass]="
                      'venture__label venture__label--head widget__label'
                    "
                    [options]="{
                      placeholder: offerringPlaceholder | translate
                    }"
                    [attributeDescription]="
                      getFieldOptions('PersonalOfferings')
                    "
                  >
                  </app-form-field-custom>
                  <app-form-field-custom
                    [formInput]="form"
                    [editable]="isCreating || (editable && section3.editting)"
                    [type]="allType.MultiString"
                    [showLabel]="true"
                    [labelClass]="
                      'venture__label venture__label--head widget__label'
                    "
                    [options]="{
                      placeholder: offerringPlaceholder | translate
                    }"
                    [attributeDescription]="
                      getFieldOptions('PersonalSearchings')
                    "
                  >
                  </app-form-field-custom>
                </ng-template>
              </li>
              <li ngbNavItem>
                <a ngbNavLink
                  ><span
                    [ngClass]="{
                      'venture__tabs__title--invalid':
                        editable && isFieldInvalid('Content')
                    }"
                    class="venture__tabs__title"
                    translate
                    >Offerings of my Organization / Company
                    <span class="after"></span></span
                ></a>
                <ng-template ngbNavContent>
                  <app-form-field-custom
                    [formInput]="form"
                    [editable]="isCreating || (editable && section3.editting)"
                    [type]="allType.MultiString"
                    [showLabel]="true"
                    [labelClass]="
                      'venture__label venture__label--head widget__label'
                    "
                    [options]="{
                      placeholder: offerringPlaceholder | translate
                    }"
                    [attributeDescription]="getFieldOptions('CompanyOfferings')"
                  >
                  </app-form-field-custom>
                  <app-form-field-custom
                    [formInput]="form"
                    [editable]="isCreating || (editable && section3.editting)"
                    [type]="allType.MultiString"
                    [showLabel]="true"
                    [labelClass]="
                      'venture__label venture__label--head widget__label'
                    "
                    [options]="{
                      placeholder: offerringPlaceholder | translate
                    }"
                    [attributeDescription]="
                      getFieldOptions('CompanySearchings')
                    "
                  >
                  </app-form-field-custom>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav"></div>
          </div>
        </app-form-section>
      </div>

      <!-- Education -->
      <div class="venture__body widget__card--background">
        <app-form-section
          #section4
          [index]="4"
          [canSave]="isValidSection(4)"
          [showEditButton]="!isCreating && editable"
          [saveSectionCallback]="saveSection.bind(this)"
          (cancelSection)="cancelSection($event)"
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <div class="row">
            <div class="col col-12 person__education">
              <div
                class="venture__label widget__label venture__label--head d-flex justify-content-between"
                translate
              >
                {{ getFieldOptions('Education').displayName }}

                <app-language-indicator
                  *ngIf="isCreating"
                ></app-language-indicator>
              </div>
              <app-form-field-custom
                [formInput]="form"
                [editable]="isCreating || (editable && section4.editting)"
                [attributeDescription]="getFieldOptions('Education')"
                [options]="{ isViewAsText: true }"
              ></app-form-field-custom>
            </div>
          </div>
        </app-form-section>
      </div>

      <div class="venture__body widget__card--background">
        <div class="row">
          <div class="col col-12">
            <div class="d-flex align-items-center mb-3">
              <div
                class="venture__label widget__label venture__label--head m-0 mr-4"
              >
                {{ 'My Ventures' | translate }}
              </div>

              <app-switch-view-control
                [(isGridView)]="isVentureGridView"
                (showExpandTable)="isSharedVentureExpanded = true"
              >
              </app-switch-view-control>

              <div class="icon-control mr-2">
                <i
                  class="fas fa-filter"
                  [class.selected]="isVentureFilterActive"
                  (click)="isVentureFilterActive = !isVentureFilterActive"
                ></i>
              </div>
            </div>

            <app-shared-ventures
              [parentEntityId]="entity?.id"
              [parentEntityName]="EntityName.Person"
              [httpService]="peopleHttpService"
              [globalOrgId]="globalOrgId"
              [paginationSettingPlace]="
                PaginationSettingPlace.VentureListInProfile
              "
              [showSubmitMenu]="true"
              [(showFilter)]="isVentureFilterActive"
              [(isGridView)]="isVentureGridView"
              [(isTableExpanded)]="isSharedVentureExpanded"
            ></app-shared-ventures>
          </div>
        </div>
      </div>

      <!-- People -->
      <div class="venture__body widget__card--background">
        <div class="row">
          <div class="col col-12">
            <div class="venture__label widget__label venture__label--head">
              {{ 'Who are Following' | translate }}
            </div>

            <app-people-cards
              [items]="entity?.followings"
              [httpService]="baseHttpService"
              [showSearchButton]="true"
              [globalOrgId]="globalOrgId"
            ></app-people-cards>
          </div>
        </div>
      </div>

      <div class="venture__body widget__card--background">
        <div class="row">
          <div class="col col-12">
            <div class="venture__label widget__label venture__label--head">
              {{ 'UI.Profile.MyEvents' | translate }}
            </div>

            <app-shared-events
              [entityId]="entity?.id"
              [httpService]="peopleHttpService"
              [pageSize]="3"
            ></app-shared-events>
          </div>
        </div>
      </div>

      <div class="venture__body widget__card--background" *ngIf="isOwner">
        <div class="row">
          <div class="col col-12">
            <div class="venture__label widget__label venture__label--head">
              {{ 'UI.PeopleEdit.RegisteredEvents' | translate }}
            </div>

            <app-shared-events
              [entityId]="entity?.id"
              [httpService]="peopleHttpService"
              [pageSize]="3"
              [isRegisteredEvent]="true"
            ></app-shared-events>
          </div>
        </div>
      </div>

      <!-- Organizations -->
      <app-deactivated-organization-warning
        *ngIf="
          !section5.editting &&
          (organizer.leadCompany | call : shouldShowDeactivatedWarning)
        "
      ></app-deactivated-organization-warning>

      <div class="venture__body widget__card--background">
        <app-form-section
          #section5
          [index]="5"
          [showEditButton]="!isCreating && editable"
          [canSave]="isValidSection(5)"
          [saveSectionCallback]="saveSection.bind(this)"
          (saveSection)="markOrgFormAsTouched()"
          (cancelSection)="
            cancelSection($event);
            cancelCompany(
              $event,
              organizer.resetCompanyInput.bind(organizer),
              'LeadCompany'
            );
            reverseOtherOrgs(otherOrgs);
            reverseLinkToExistingOrg()
          "
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <div class="row">
            <div class="col">
              <app-organizer
                #organizer
                class="w-100"
                deactivatedOrgIcon="fas fa-times"
                deactivatedOrgLabel="UI.LeadOrgUnavailable"
                [isFromPeople]="true"
                [leadCompany]="form.getRawValue()?.LeadCompany"
                [searchMode]="orgsSearchMode"
                [isPending]="
                  entity?.leadCompany?.isPending &&
                  entity?.leadCompany?.id == form.value?.LeadCompany?.id
                "
                [isOwner]="isOwner"
                [disabledSection]="
                  !(isCreating || (editable && section5.editting))
                "
                [childClass]="'col-12 col-lg-6'"
                [labelClass]="'venture__label--head'"
                [labelSectionName]="getFieldOptions('Company')?.displayName"
                [editing]="isCreating || (isOwner && section5.editting)"
                [(isLinkedFromParent)]="isLinkedToExistingOrg"
                [markOrgFormTouched]="markOrgFormTouched"
                [userId]="profile?.id"
                [otherOrganisationIds]="searchIgnoredLeadOrgIds"
                (orgFormChange)="updateForm($event)"
                (orgFormInvalidChanges)="updateLeadOrgFormError($event)"
                (isLinkedFromParentChange)="toggleExistingOrgLinking($event)"
                [nameAttributeDescription]="getFieldOptions('Company')"
                [logoAttributeDescription]="getFieldOptions('CompanyLogo')"
                [urlAttributeDescription]="getFieldOptions('CompanyLink')"
                [leadCompanyAttribute]="getFieldOptions('LeadCompany')"
                [showRequireHint]="true"
                (changeFormStatus)="section5.toggle($event)"
              >
                <div
                  class="form-group"
                  [class]="'col-12 col-lg-6'"
                  *ngIf="organizer.isExternalOrg"
                >
                  <app-form-field-custom
                    class="group-item group-input"
                    [formInput]="form"
                    [editable]="isCreating || (editable && section5.editting)"
                    [showLabel]="section5.editting"
                    [attributeDescription]="getFieldOptions('CompanySize')"
                  >
                  </app-form-field-custom>
                </div>
              </app-organizer>

              <div
                *ngIf="
                  showLeadOrgError() &&
                  (isCreating || (isOwner && section5?.editting))
                "
                class="alert alert-danger"
              >
                <span>{{ 'UI.Common.Lead-Org-is-required' | translate }}</span>
              </div>

              <app-person-organisations
                #otherOrgs
                [organisations]="entity?.organizations || []"
                [ignoreCompany]="form.getRawValue()?.LeadCompany"
                [editing]="isCreating || (isOwner && section5.editting)"
                [userId]="profile?.id"
                (notifyOrganisationPayload)="updateOrganisationPayload($event)"
              ></app-person-organisations>
            </div>
          </div>
        </app-form-section>
      </div>
    </div>
  </form>
</ng-container>
