<div class="news-messages__item news-messages__item--flat clearfix mb-1">
  <div
    class="news-messages__wrapper pt-0"
    [ngClass]="{
      'news-messages__wrapper--rtl': direction === TextDirection.Right
    }"
  >
    <ng-container *ngIf="message">
      <app-message-header-wrapper
        [people]="message.from"
        [messageDate]="message.dateTime"
        [hideMessageDate]="true"
      >
        <div>
          <!-- placeholder only -->
          <div>&nbsp;</div>
          <ng-container *ngIf="message.from; else replaceText">
            <div
              class="d-flex sender-wrapper"
              [ngClass]="{
                'flex-row-reverse sender-wrapper': message.from?.isBot
              }"
            >
              <app-bot-flag
                *ngIf="message.from?.isBot"
                [parentClass]="'ml-1 d-inline-block'"
              ></app-bot-flag>
              <a
                appUnsavedFormCheck
                class="news-messages__owner-name"
                [href]="peopleURL + '/' + message.from?.id"
              >
                {{ message.from?.firstName }} {{ message.from?.lastName }}&nbsp;
              </a>
            </div>
          </ng-container>
          <ng-template #replaceText>
            <div class="deactive-label">
              {{ 'UI.UnsubscribedUser' | translate }}
            </div>
          </ng-template>

          <div
            class="news-messages__date-time d-flex align-items-center"
            style="direction: ltr"
          >
            <div
              *ngIf="direction === TextDirection.Right"
              class="d-flex align-items-center"
            >
              <span
                *ngIf="message.isForward"
                class="d-flex align-items-center"
                style="direction: rtl"
                >Forwared
                <app-internal-icon
                  [name]="InternalIcon.Forward"
                  [height]="16"
                  [minWidth]="16"
                ></app-internal-icon
              ></span>
              <span *ngIf="message.isForward && message.isCC" class="d-flex align-items-center">
                <span class="mx-1">&</span>
                <app-internal-icon
                  [name]="InternalIcon.CCIcon"
                  [height]="16"
                  [minWidth]="16"
                ></app-internal-icon>
                CCed <span class="mx-1"> | </span></span
              >
            </div>
            {{
              message.dateTime | localizedDate : DateFormat.FullDateTimeCommon
            }}
          </div>
        </div>
      </app-message-header-wrapper>

      <div class="d-flex align-items-center">
        <div
          *ngIf="direction === TextDirection.Right && message.parentId"
          ngbDropdown
          placement="bottom-right"
          class="dropdown-no-arrow"
        >
          <a class="p-2" role="button" ngbDropdownToggle appClickPreventDefault>
            <i class="fa fa-ellipsis-v align-middle"></i>
          </a>
          <ul ngbDropdownMenu>
            <li
              ngbDropdownItem
              class="venture__actions-item p-0"
              (click)="onDelete()"
            >
              <a
                class="link-unstyled w-100 p-2 d-flex align-items-center flex-row-reverse"
              >
                <i
                  class="mr-2 fas fa-trash"
                  [ngbTooltip]="'UI.DeactivatePost.Tooltip' | translate"
                ></i>
                <span class="venture__actions-label font-weight-bold" translate>
                  UI.DeleteThreadMessage.DeleteMessage
                </span>
              </a>
            </li>
          </ul>
        </div>
        <div
          class="news-messages__body news-messages__body--rounded d-inline-block mw-50 p-2"
          [ngClass]="
            direction === TextDirection.Right
              ? 'float-right news-messages__body--dark-bg news-messages__body--owner'
              : 'news-messages__body--light-bg'
          "
        >
          <quill-view
            [content]="message.message || message.content"
          ></quill-view>
        </div>
      </div>
      <div *ngIf="message.image" class="mw-50 p-2">
        <img
          class="news-messages__image"
          [class.float-right]="direction === TextDirection.Right"
          [src]="message.image"
          alt="message image"
        />
      </div>
    </ng-container>
  </div>
</div>
