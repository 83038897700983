<div class="modal-header">
  <h4 class="modal-title" translate>Profile Detail</h4>
</div>
<div class="modal-body modal-body-confirmation">
  <div class="mb-3">
    We are glad to have you on jointcreate - please enter information about you
    and your lead organization
  </div>
  <div class="d-flex justify-content-center px-2 pt-2">
    <app-enforce-profile
      class="w-100"
      [isCreating]="false"
      [id]="id"
    ></app-enforce-profile>
  </div>
</div>
