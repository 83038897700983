<app-unsaved-form-check
  [unsavedFormCheckService]="unsavedFormCheckService"
></app-unsaved-form-check>

<ng-container *ngIf="!isLoadingEntity && !isGoingToOtherPage">
  <form class="venture-edit">
    <div
      *ngIf="form"
      [class.pb-3]="!isCreating"
      class="venture__wrapper org-details pt-4"
    >
      <!--Create organization header-->
      <div
        *ngIf="!isShownAsChallenge && isCreating"
        class="px-3 pt-3 background-white"
      >
        <p
          class="font-weight-bold font-size-2xl m-0"
          [ngClass]="{
            'mt-3': !defaultLogo.url
          }"
        >
          Create Organization
        </p>
        <p
          class="m-0"
          [ngClass]="{
            'mt-3': !defaultLogo.url
          }"
        >
          This will represent a company, a company department or any other
          organization on {{ sessionService.tenant.name ?? '' }}
        </p>
      </div>
      <!-- Org Logo, Name, and URL -->
      <div class="org-detail-header widget__card--background pb-3">
        <app-form-section
          #orgGeneralInfo
          [index]="orgSectionIndex.GeneralInfo"
          [canSave]="isValidSection(orgSectionIndex.GeneralInfo)"
          [showEditButton]="!isCreating && editable"
          [showLanguageSwitcher]="!isCreating && orgGeneralInfo.editting"
          [saveSectionCallback]="saveSection.bind(this)"
          (cancelSection)="cancelSection($event)"
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <div
            *ngIf="!isCreating && !orgGeneralInfo.editting"
            class="organization__brand brand-detail m-0"
          >
            <span class="organization__logo d-flex">
              <img
                [src]="form?.value?.Logo?.url || form?.value?.Logo"
                appDefaultPlaceholder
                [default]="placeholderImg"
                [alt]="entity?.displayText"
                [ngbTooltip]="entity?.orgName"
                appDynamicImgUrl
                container="body"
              />
            </span>

            <div class="organization__name-container">
              <div class="organization__name brand-name d-flex">
                <span>{{ form?.value?.OrgName }}</span>
                <a
                  *ngIf="form?.value?.OrgUrl?.url"
                  target="_blank"
                  [value]="form.value.OrgUrl.url"
                  appUrlPrefix
                  class="mx-2"
                  [ngbTooltip]="orgWebsiteRef"
                >
                  <app-internal-icon
                    [name]="InternalIcon.GlobeCursor"
                    [height]="18"
                    [minWidth]="19.5"
                  ></app-internal-icon>
                </a>
                <ng-template #orgWebsiteRef>
                  <div class="d-flex flex-column align-items-center">
                    <span>{{
                      'UI.Organization.ExternalWebsiteTooltip' | translate
                    }}</span>
                    <span class="text-white font-weight-bold">
                      {{ form?.value?.OrgUrl?.url }}
                    </span>
                  </div>
                </ng-template>
              </div>
              <div class="organization__sub-name enclosing-name">
                <a
                  [href]="form?.value?.EnclosingOrgUrl"
                  class="text-reset"
                  target="_blank"
                >
                  {{ form?.value?.EnclosingOrgName }}
                </a>
              </div>
            </div>

            <div class="organization__header-action">
              <div class="organization__created-time">
                {{
                  form?.value?.CreatedDate
                    | localizedDate : DateFormat.MetadataTable
                }}
              </div>
              <div class="d-flex align-items-center justify-content-center">
                <ng-container
                  *ngIf="isOwner && !isShownAsChallenge"
                  class="d-flex align-items-center"
                >
                  <button
                    type="button"
                    class="btn btn-primary btn-interact btn-interact--big mr-2"
                    appRequireLogin
                    appDebounceClick
                    (debounceClick)="openDeactivateOrgPopup()"
                  >
                    <i class="fas fa-lock"></i>
                    <span class="text-hide-small">
                      {{ 'Deactivate my organization' | translate }}
                    </span>
                  </button>
                  <button
                    type="button"
                    class="btn btn-outline-primary btn-interact btn-interact--big"
                    appRequireLogin
                    appDebounceClick
                    (debounceClick)="openExportPopup()"
                  >
                    <i class="fas fa-file-export"></i>
                    <span class="text-hide-small">
                      {{ 'UI.Common.Export' | translate }}
                    </span>
                  </button>
                </ng-container>

                <ng-container
                  *ngIf="
                    entity && entity.showAsChallenge && entity.challengeTimeline
                  "
                >
                  <div
                    class="d-flex align-items-center justity-content-center"
                    [ngbTooltip]="
                      getReasonWhyCannotSubmitVentureToCall(entity) | translate
                    "
                    [disableTooltip]="canSubmitVentureToCall(entity)"
                    (click)="$event.preventDefault()"
                  >
                    <button
                      class="btn btn-primary btn-interact btn-interact--big ml-2"
                      appRequireLogin
                      appDebounceClick
                      (debounceClick)="submitVentureToOrg()"
                      [disabled]="!canSubmitVentureToCall(entity)"
                    >
                      <i class="fas fa-upload"></i>
                      <span class="text-hide-small">
                        {{ 'UI.Common.Submit' | translate }}</span
                      >
                    </button>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
          <div
            class="org-header-edit"
            *ngIf="isCreating || (editable && orgGeneralInfo.editting)"
          >
            <div class="row">
              <div class="col">
                <div class="form-group position-relative mt-2">
                  <div class="venture__label widget__label d-flex">
                    <label for="logo">
                      {{ getEntityMetaInfo() | fieldLabel : 'Logo' }}
                    </label>
                  </div>
                  <div class="d-flex">
                    <div
                      class="upload-file__container position-relative"
                      [ngClass]="{
                        'extend-container': !form.value?.Logo?.url
                      }"
                    >
                      <app-file-upload
                        #logoUpload
                        [multiLocale]="false"
                        [disabled]="false"
                        (srcUrlChange)="preview($event)"
                        [inputId]="
                          (entityMetaInfo | fieldOption : 'Logo')?.propertyName
                        "
                      >
                        <div
                          *ngIf="!form.value?.Logo?.url"
                          class="card w-fit mr-auto mb-0 upload-file__big-upload-btn extend-container d-flex align-items-center flex-row"
                          [ngClass]="{ 'alert alert-danger': logo.invalid }"
                        >
                          <i
                            class="fas fa-camera fa-lg"
                            [ngClass]="{ 'upload-file__icon': logo.valid }"
                          ></i>
                          <span>{{
                            'UI.UploadImageButton.FieldIsRequired' | translate
                          }}</span>
                        </div>
                        <ng-container *ngIf="form.value?.Logo?.url">
                          <div>
                            <div
                              class="card upload-file__container border-0 position-relative"
                            >
                              <img
                                [src]="form.value?.Logo?.url"
                                appDefaultPlaceholder
                                appDynamicImgUrl
                                [default]="placeholderImg"
                              />
                            </div>
                          </div>
                        </ng-container>
                      </app-file-upload>
                    </div>
                    <div
                      *ngIf="isCreating && defaultLogo.url"
                      class="upload-control-wrapper ml-2 d-flex flex-column-reverse"
                    >
                      <div class="d-flex">
                        <div
                          appRequireSession
                          class="d-flex align-items-center pointer-cursor"
                          (credentialClick)="logoUpload?.triggerUpload()"
                        >
                          <span
                            class="upload-control-wrapper__change-logo d-block"
                          >
                            <i class="fas fa-pencil-alt"></i>
                          </span>
                          <span
                            class="ml-1 upload-control-wrapper__control-text"
                            translate
                            >UI.DefaultImageControl.ChangeLogo</span
                          >
                        </div>
                        <ng-container
                          *ngIf="form.value?.Logo?.id !== defaultLogo.id"
                        >
                          <div
                            appRequireSession
                            class="d-flex align-items-center upload-control-wrapper__back-to-default pointer-cursor"
                            (credentialClick)="resetImage('Logo')"
                          >
                            <span
                              class="upload-control-wrapper__change-logo d-block"
                            >
                              <i class="fas fa-undo-alt"></i>
                            </span>
                            <span
                              class="ml-1 upload-control-wrapper__control-text"
                              translate
                              >UI.DefaultImageControl.BackToDefaultLogo</span
                            >
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col col-12 col-md-6">
                <label class="venture__label widget__label">
                  {{ getEntityMetaInfo() | fieldLabel : 'OrgName' }}
                </label>
                <app-form-field-custom
                  [formInput]="form"
                  [editable]="
                    isCreating || (editable && orgGeneralInfo.editting)
                  "
                  [type]="allType.StringSingle"
                  [attributeDescription]="
                    entityMetaInfo | fieldOption : 'OrgName'
                  "
                  [options]="{
                    checkDuplicatedName: !isShownAsChallenge ? true : false
                  }"
                  [httpService]="orgHttpService"
                >
                </app-form-field-custom>
              </div>
              <div class="col col-12 col-md-6">
                <label class="venture__label widget__label">
                  {{ getEntityMetaInfo() | fieldLabel : 'OrgUrl' }}
                </label>
                <app-form-field-custom
                  [formInput]="form"
                  [editable]="
                    isCreating || (editable && orgGeneralInfo.editting)
                  "
                  [type]="allType.Link"
                  [attributeDescription]="
                    entityMetaInfo | fieldOption : 'OrgUrl'
                  "
                >
                </app-form-field-custom>
              </div>
            </div>
            <div class="row">
              <div class="col col-12 col-md-6">
                <app-form-field-custom
                  [formInput]="form"
                  [editable]="
                    isCreating || (editable && orgGeneralInfo.editting)
                  "
                  [showLabel]="true"
                  [type]="allType.StringSingle"
                  [attributeDescription]="
                    entityMetaInfo | fieldOption : 'EnclosingOrgName'
                  "
                >
                </app-form-field-custom>
              </div>
              <div class="col col-12 col-md-6">
                <div class="venture__label widget__label">
                  {{
                    'UI.ORG-DETAIL.Alias-text-for-link-to-webpage' | translate
                  }}
                </div>
                <div class="form-field__content">
                  <div class="form-field__body ng-dirty ng-valid ng-touched">
                    <div>
                      <input
                        class="form-control ng-pristine ng-valid ng-touched"
                        name="orgUrlDisplayText"
                        [(ngModel)]="orgUrlDisplayText"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </app-form-section>
      </div>

      <!-- Challenge toggle & Privacy-->
      <div
        *ngIf="!isShownAsChallenge && (isCreating || isOwner)"
        class="org-detail-header widget__card--background mt-4"
      >
        <app-form-section
          #orgPrivacy
          [index]="orgSectionIndex.Privacy"
          [canSave]="isValidSection(orgSectionIndex.Privacy)"
          [showEditButton]="!isCreating && editable"
          [saveSectionCallback]="
            changeOrgPrivacy.bind(this, orgSectionIndex.Privacy)
          "
          (cancelSection)="cancelSection($event)"
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <div class="row">
            <div class="col-12 my-2">
              <div class="venture__label widget__label mb-3">
                {{ getEntityMetaInfo() | fieldLabel : 'IsPrivate' }}
              </div>
              <div [formGroup]="form">
                <div class="organization__radio-item">
                  <input
                    formControlName="IsPrivate"
                    name="IsPrivate"
                    id="isPublic"
                    type="radio"
                    class="organization__radio"
                    [value]="false"
                    [class.disabled-input]="
                      !isCreating && !orgPrivacy?.editting
                    "
                  />
                  <label
                    for="isPublic"
                    class="radio-info d-flex flex-column"
                    [class.disabled-action]="
                      !isCreating && !orgPrivacy?.editting
                    "
                  >
                    <span class="radio-text">
                      <i class="fas fa-globe-europe"></i>
                      {{ 'Public' | translate }}
                    </span>
                    <span
                      class="radio-hint"
                      [innerHtml]="
                        'UI.ORG-DETAIL.Public-org-hint'
                          | translate
                          | stringReplacement
                            : {
                                '{portalName}': '#' | linkWrapper : portalName
                              }
                      "
                    ></span>
                  </label>
                </div>
                <div class="organization__radio-item">
                  <input
                    formControlName="IsPrivate"
                    name="IsPrivate"
                    id="isPrivate"
                    class="organization__radio"
                    type="radio"
                    [value]="true"
                    [class.disabled-input]="
                      !isCreating && !orgPrivacy?.editting
                    "
                  />
                  <label
                    for="isPrivate"
                    class="radio-info d-flex flex-column"
                    [class.disabled-action]="
                      !isCreating && !orgPrivacy?.editting
                    "
                  >
                    <span class="radio-text">
                      <i class="fas fa-lock"></i>
                      {{ 'Private' | translate }}
                    </span>
                    <span class="radio-hint radio-hint--warning">
                      {{ 'UI.ORG-DETAIL.Private-org-hint' | translate }}
                    </span>
                  </label>
                </div>
              </div>

              <div>
                <div class="venture__label widget__label mb-3">
                  Select who can send messages to the participants
                </div>
                <app-form-field-custom
                  [controlName]="'SendMessageRights'"
                  [formInput]="form"
                  [attributeDescription]="getFieldOptions('SendMessageRights')"
                  [editable]="isCreating || (editable && orgPrivacy.editting)"
                >
                </app-form-field-custom>
              </div>
            </div>
          </div>
        </app-form-section>
      </div>

      <ng-container
        *ngIf="isShownAsChallenge || (!isShownAsChallenge && !isCreating)"
      >
        <ng-container *ngTemplateOutlet="imageDescriptionSection">
        </ng-container>
      </ng-container>
      <ng-template #imageDescriptionSection>
        <!-- Org Image & Headline -->
        <div class="org-detail-image">
          <div
            class="d-flex py-2 px-3 background-white justify-content-between align-items-center"
            [ngClass]="{
              'flex-row-reverse': !(isCreating && defaultBanner.url)
            }"
          >
            <div
              *ngIf="isCreating && defaultBanner.url"
              class="upload-control-wrapper d-flex flex-column-reverse"
            >
              <div class="d-flex">
                <div
                  appRequireSession
                  class="d-flex align-items-center pointer-cursor"
                  (credentialClick)="
                    imageControlInCreateMode?.imageUpload?.triggerUpload()
                  "
                >
                  <span class="upload-control-wrapper__change-logo d-block">
                    <i class="fas fa-pencil-alt"></i>
                  </span>
                  <span
                    class="ml-1 upload-control-wrapper__control-text"
                    translate
                    >UI.DefaultImageControl.ChangeBanner</span
                  >
                </div>
                <ng-container
                  *ngIf="form.value?.Image?.url !== defaultBanner.url"
                >
                  <div
                    appRequireSession
                    class="d-flex align-items-center upload-control-wrapper__back-to-default pointer-cursor"
                    (credentialClick)="resetImage('Image')"
                  >
                    <span class="upload-control-wrapper__change-logo d-block">
                      <i class="fas fa-undo-alt"></i>
                    </span>
                    <span
                      class="ml-1 upload-control-wrapper__control-text"
                      translate
                      >UI.DefaultImageControl.BackToDefaultBanner</span
                    >
                  </div>
                </ng-container>
              </div>
            </div>
            <app-language-indicator *ngIf="isCreating"></app-language-indicator>
          </div>

          <div class="image-headline-container">
            <div
              *ngIf="editable && !isCreating"
              [ngClass]="imageControl?.isInvalid ? 'alert-danger' : 'bg-white'"
              class="upload-file__container justify-content-between w-100 shadow-none"
            >
              <div class="org-details__image-upload--primary btn-primary">
                <app-form-field-custom
                  #imageControl
                  styleClass="fa-image text-white fill-container small-image-upload-btn"
                  [attributeDescription]="
                    entityMetaInfo | fieldOption : 'Image'
                  "
                  [formInput]="getFormSectionIndex('Image')"
                  [language]="getLocaleBySectionKey('Image')"
                  [options]="{
                    id: form?.value?.Image?.id,
                    multiLocale: isCreating
                  }"
                  [allowCropImg]="true"
                  [aspectRatio]="bannerImageCropRatio"
                >
                </app-form-field-custom>
              </div>

              <app-language-switcher
                (languageRemoved)="onRemoveLanguage('Image')"
                (languageSelected)="onLanguageChange($event, 'Image')"
                *ngIf="!isCreating"
                [className]="'mr-3'"
              ></app-language-switcher>
            </div>

            <app-image-wrapper
              imgClass="widget__image venture__image"
              [imgAlt]="getFormSectionIndex('Image')?.value?.OrgName"
              [imgObject]="getFormSectionIndex('Image')?.value?.Image"
            ></app-image-wrapper>

            <div
              *ngIf="form?.value?.Headline || form?.value?.SubHeadline"
              class="headline-container"
            >
              <span
                class="headline"
                appTooltip
                triggers="manual"
                #t2="ngbTooltip"
                [toolTipControl]="t2"
                [ngbTooltip]="form?.value?.Headline"
                >{{ form?.value?.Headline | uppercase }}</span
              >
              <span
                class="subheadline"
                appTooltip
                triggers="manual"
                #t3="ngbTooltip"
                [toolTipControl]="t3"
                [ngbTooltip]="form?.value?.SubHeadline"
                >{{ form?.value?.SubHeadline }}</span
              >
            </div>
            <div
              *ngIf="isCreating"
              [ngStyle]="{ top: '1.25rem', left: '1.25rem' }"
              class="position-absolute mr-3"
              [ngClass]="{
                'alert-dange': imageControlInCreateMode?.isInvalid,
                'bg-white': !imageControlInCreateMode?.isInvalid,
                'extend-container': !form?.value?.Image?.url
              }"
            >
              <app-form-field-custom
                #imageControlInCreateMode
                [attributeDescription]="entityMetaInfo | fieldOption : 'Image'"
                [formInput]="getFormSectionIndex('Image')"
                [language]="getLocaleBySectionKey('Image')"
                [options]="{
                  id:
                    imageValue?.id !== defaultBanner.id ? imageValue?.id : null,
                  multiLocale: isCreating
                }"
                [allowCropImg]="true"
                [aspectRatio]="bannerImageCropRatio"
                styleClass="upload-file__big-upload-btn"
                [customUploadButtonTemplate]="customUploadButtonTemplate"
                [displayFileLoading]="false"
              >
              </app-form-field-custom>

              <ng-template #customUploadButtonTemplate>
                <div
                  *ngIf="defaultBanner.url; else defaultImageUploadButton"
                ></div>
                <ng-template #defaultImageUploadButton>
                  <div
                    class="card w-fit mr-auto mb-0 upload-file__big-upload-btn extend-container d-flex align-items-center flex-row"
                    [ngClass]="{
                      'alert alert-danger': imageControlInCreateMode?.isInvalid,
                      'extend-container': !form?.value?.Image?.url
                    }"
                  >
                    <i
                      class="fas fa-camera fa-lg"
                      [ngClass]="{
                        'upload-file__icon':
                          !imageControlInCreateMode?.isInvalid
                      }"
                    ></i>
                    <span *ngIf="!form?.value?.Image?.url">{{
                      'UI.UploadImageButton.FieldIsRequired' | translate
                    }}</span>
                  </div>
                </ng-template>
              </ng-template>

              <app-language-switcher
                (languageSelected)="onLanguageChange($event, 'Image')"
                (languageRemoved)="onRemoveLanguage('Image')"
                *ngIf="!isCreating"
                [className]="'mr-3'"
              ></app-language-switcher>
            </div>
          </div>
        </div>

        <!-- Org Interaction Bar -->
        <ng-container *ngIf="!isCreating">
          <app-interaction-bar
            entityPath="{{ entity | challengeOrgUrl }}"
            [httpService]="
              isShownAsChallenge ? challengeHttpService : orgHttpService
            "
            [entity]="entity"
            [entityName]="
              isShownAsChallenge ? EntityName.Challenge : entityName
            "
            [isLoadingLikingsCount]="isLoadingLikingsCount"
            [isLoadingFollowingsCount]="isLoadingFollowingsCount"
            [isLoadingCommentsCount]="isLoadingCommentsCount"
            (triggerToggleLike)="toggleLike(entity)"
            (triggerShowLikingsData)="
              showEntityData(); showLikesModal = !!entity?.likesCount
            "
            (triggerShowFollowingsData)="
              showEntityData(); showPeopleModal = true
            "
            (afterCommentModalClosed)="onCommentModalClosed()"
            (triggerToggleFavorite)="toggleFavorite()"
          >
            <!-- //NOSONAR -->
            <li
              *ngIf="isOwner"
              class="list-inline-item venture__actions-item"
              role="button"
            >
              <a
                appRequireLogin
                appDebounceClick
                (debounceClick)="
                  showEntityData(); showPendingPeopleModal = true
                "
              >
                <app-internal-icon
                  [height]="20"
                  [name]="'user-clock'"
                  [className]="'filter-gray'"
                ></app-internal-icon>
                <span
                  class="venture__actions-label font-weight-500 text-hide-small"
                >
                  <span class="text-hide-small">{{
                    'PendingRequests' | translate
                  }}</span>
                  <span
                    [innerHTML]="entity?.pendingRequestCount | parentheses"
                  ></span>
                </span>
              </a>
            </li>

            <div class="d-flex">
              <app-join-organization
                [isAllowToJoin]="
                  !entity.showAsChallenge || entity.isAllowToJoin
                "
                [baseHttpService]="baseHttpService"
                [entity]="entity"
                (afterJoined)="getLatestEntity(id)"
              ></app-join-organization>
            </div>
          </app-interaction-bar>
          <div
            class="p-3 org-detail-header widget__card--background d-flex flex-column"
          >
            <b class="mb-2 filter-token-text"
              >{{ 'UI.FilterToken.Text' | translate }}
            </b>

            <div class="d-flex flex-column flex-md-row">
              <button
                type="button"
                appDebounceClick
                onmouseup="this.blur();"
                class="btn btn-primary form-section__btn mr-3 ml-0"
                (click)="addOrgToFilterToken(entity?.id)"
              >
                <i class="fas fa-plus-square btn-icon-right"></i>
                <span>{{ 'UI.FilterToken.AddOrganization' | translate }}</span>
              </button>
              <div
                class="venture__label widget__label--no-truncate d-flex align-items-md-center align-items-start mb-0 mt-1 mt-md-0"
              >
                <app-copy-text
                  [textToCopy]="getFilterTokenUrl()"
                ></app-copy-text>
                <span class="ml-1">
                  {{ 'UI.FilterToken.Copy' | translate }}
                </span>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Headline, SubHeadLine, Description -->
        <div class="venture__body widget__card--background">
          <app-form-section
            #orgDescription
            [index]="orgSectionIndex.Description"
            [canSave]="isValidSection(orgSectionIndex.Description)"
            [showEditButton]="!isCreating && editable"
            [saveSectionCallback]="saveSection.bind(this)"
            (cancelSection)="cancelSection($event)"
            (editIndexChange)="markEditingSectionIndex($event)"
          >
            <div class="row">
              <div class="col col-12">
                <div
                  *ngIf="isCreating || (editable && orgDescription.editting)"
                  class="d-flex flex-row-reverse pb-2 gray-border-bottom"
                >
                  <app-language-indicator
                    *ngIf="isCreating"
                  ></app-language-indicator>
                  <app-language-switcher
                    (languageSelected)="
                      onLanguageChange($event, orgSectionIndex.Description)
                    "
                    *ngIf="!isCreating && editable && orgDescription.editting"
                  ></app-language-switcher>
                </div>

                <app-form-field-custom
                  [formInput]="getFormSectionIndex(orgSectionIndex.Description)"
                  [showLabel]="true"
                  [labelClass]="'venture__label--head'"
                  [editable]="
                    isCreating || (editable && orgDescription.editting)
                  "
                  [type]="allType.StringSingle"
                  [attributeDescription]="
                    entityMetaInfo | fieldOption : 'Headline'
                  "
                  [options]="{ isViewAsText: true }"
                ></app-form-field-custom>
              </div>
            </div>
            <div class="row">
              <div class="col col-12">
                <app-form-field-custom
                  [formInput]="getFormSectionIndex(orgSectionIndex.Description)"
                  [showLabel]="true"
                  [labelClass]="'venture__label--head'"
                  [editable]="
                    isCreating || (editable && orgDescription.editting)
                  "
                  [type]="allType.StringSingle"
                  [attributeDescription]="
                    entityMetaInfo | fieldOption : 'SubHeadline'
                  "
                  [options]="{ isViewAsText: true }"
                ></app-form-field-custom>
              </div>
            </div>
            <div class="row">
              <div class="col col-12">
                <app-form-field-custom
                  [formInput]="getFormSectionIndex(orgSectionIndex.Description)"
                  [showLabel]="true"
                  [labelClass]="'venture__label--head'"
                  [editable]="
                    isCreating || (editable && orgDescription.editting)
                  "
                  [attributeDescription]="
                    entityMetaInfo | fieldOption : 'Description'
                  "
                  [options]="{ isViewAsText: true, areRowsLimited: false }"
                >
                </app-form-field-custom>
              </div>
            </div>
            <div class="row">
              <div
                *ngIf="!isShownAsChallenge"
                class="col col-12 organization__size"
              >
                <app-form-field-custom
                  [labelClass]="'venture__label--head'"
                  [formInput]="form"
                  [editable]="
                    isCreating || (editable && orgDescription.editting)
                  "
                  [showLabel]="true"
                  [attributeDescription]="
                    entityMetaInfo | fieldOption : 'OrganizationSize'
                  "
                >
                </app-form-field-custom>
              </div>
            </div>
            <div *ngIf="canShowAttachments" class="row">
              <div class="col">
                <app-form-field-custom
                  [type]="allType.Files"
                  [formInput]="form"
                  [showLabel]="true"
                  [labelClass]="'venture__label--head'"
                  [editable]="
                    isCreating || (editable && orgDescription.editting)
                  "
                  [attributeDescription]="
                    entityMetaInfo | fieldOption : 'Attachments'
                  "
                  [options]="{ showHiddenMarkBackground: false }"
                ></app-form-field-custom>
              </div>
            </div>
          </app-form-section>
        </div>
      </ng-template>

      <ngb-accordion
        *ngIf="!isShownAsChallenge && isCreating"
        [activeIds]="['organization-banner-panel']"
      >
        <ngb-panel
          id="organization-banner-panel"
          cardClass="venture__body venture__accordion ignore-overflow partner-org-list-panel"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button class="btn panel__header" ngbPanelToggle type="button">
              <div translate class="pull-left float-left">
                <span
                  class="venture__company-header d-block d-lg-inline-block mr-5"
                >
                  Organization Banner
                </span>
              </div>
              <i
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
          </ng-template>
          <ng-template ngbPanelContent>
            <ng-container *ngTemplateOutlet="imageDescriptionSection">
            </ng-container>
          </ng-template>
        </ngb-panel>
      </ngb-accordion>
      <!-- Colapse Pane: People | Ventures | Events | Expertises | Location -->
      <ngb-accordion
        [activeIds]="[
          'people-panel',
          'partner-org-panel',
          'partner-challenge-panel',
          'ventures-panel',
          'events-panel',
          SectionId.JuryPanel,
          'chart-panel',
          'expertise-panel',
          'timeline-panel',
          'location-panel',
          isCreating ? 'advanced-setting-panel' : '',
          'org-custom-attributes-panel'
        ]"
      >
        <!-- Challenge Timeline -->
        <ngb-panel
          *ngIf="isShownAsChallenge"
          cardClass="venture__body venture__accordion ignore-overflow"
          id="timeline-panel"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button class="btn panel__header" ngbPanelToggle type="button">
              <div translate class="pull-left float-left">
                <span
                  class="venture__company-header mr-5 d-block d-lg-inline-block"
                >
                  {{ [challengeAttributes] | fieldLabel : 'ChallengeTimeline' }}
                </span>
              </div>
              <i
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <div
              class="venture__body widget__card--background partner-orgs-section"
            >
              <app-form-section
                #challengeTimeline
                (cancelSection)="
                  cancelSection($event); appTimeline.cancelEditing()
                "
                (editIndexChange)="markEditingSectionIndex($event)"
                (saveSection)="appTimeline.cancelEditing()"
                (saveSuccess)="refreshTableView(isVentureGridView)"
                [canSave]="challengeTimelineCtrl.valid"
                [index]="orgSectionIndex.ChallengeTimeline"
                [saveSectionCallback]="saveSection.bind(this)"
                [showEditButton]="!isCreating && editable"
              >
                <app-timeline
                  #appTimeline
                  [editing]="
                    isCreating || (editable && challengeTimeline.editting)
                  "
                  [data]="challengeTimelineValues"
                  [timelineDescription]="
                    [challengeAttributes] | fieldOption : 'ChallengeTimeline'
                  "
                  (valueChange)="onTimelineValueChange($event)"
                ></app-timeline>
              </app-form-section>
            </div>
          </ng-template>
        </ngb-panel>

        <!--Organization Specific Information-->
        <ng-container *ngIf="shouldShowOrgCustomAttributes()">
          <ngb-panel
            cardClass="venture__body venture__accordion ignore-overflow partner-org-list-panel"
            id="org-custom-attributes-panel"
          >
            <ng-template ngbPanelHeader let-opened="opened">
              <button class="btn panel__header" ngbPanelToggle type="button">
                <div translate class="pull-left float-left">
                  <span
                    class="venture__company-header d-block d-lg-inline-block mr-5"
                    translate
                  >
                    UI.Organization.OrganizationSpecificInformation
                  </span>
                </div>
                <i
                  class="fas float-right pull-right venture__company-header"
                  [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                ></i>
              </button>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="venture__body widget__card--background">
                <app-form-section
                  #orgCustomAttribute
                  [index]="orgSectionIndex.OrganizationSpecificInformation"
                  [canSave]="
                    isValidSection(
                      orgSectionIndex.OrganizationSpecificInformation
                    )
                  "
                  [showEditButton]="!isCreating && editable"
                  [saveSectionCallback]="saveSection.bind(this)"
                  (cancelSection)="cancelSection($event)"
                  (editIndexChange)="markEditingSectionIndex($event)"
                >
                  <ng-container>
                    <app-organization-custom-attributes
                      [formInput]="form"
                      [editable]="
                        isCreating || (editable && orgCustomAttribute.editting)
                      "
                      [metadataRecord]="customOrganizationMetaData"
                    ></app-organization-custom-attributes> </ng-container
                ></app-form-section>
              </div>
            </ng-template>
          </ngb-panel>
        </ng-container>

        <!-- Organization Sequence -->
        <ng-container *ngIf="!isShownAsChallenge">
          <!-- Partner Organizations & Networks -->
          <ngb-panel
            cardClass="venture__body venture__accordion ignore-overflow partner-org-list-panel"
            id="partner-org-panel"
          >
            <ng-template ngbPanelHeader let-opened="opened">
              <button class="btn panel__header" ngbPanelToggle type="button">
                <div
                  translate
                  class="pull-left float-left d-flex align-items-center"
                >
                  <span
                    class="venture__company-header d-block d-lg-inline-block mr-3"
                  >
                    {{
                      getEntityMetaInfo() | fieldLabel : 'PartnerOrganizations'
                    }}
                  </span>
                  <app-switch-view-control
                    [(isGridView)]="isPartnerOrgGridView"
                    (showExpandTable)="isPartnerOrgTableExpanded = true"
                  >
                  </app-switch-view-control>
                </div>
                <i
                  class="fas float-right pull-right venture__company-header"
                  [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                ></i>
              </button>
            </ng-template>
            <ng-template ngbPanelContent>
              <ng-container *ngTemplateOutlet="parterOrganizationsPanelContent">
              </ng-container>
            </ng-template>
          </ngb-panel>
          <!-- Challenges -->
          <ng-container>
            <ngb-panel
              cardClass="venture__body venture__accordion ignore-overflow partner-org-list-panel"
              id="partner-challenge-panel"
            >
              <ng-template ngbPanelHeader let-opened="opened">
                <button class="btn panel__header" ngbPanelToggle type="button">
                  <div
                    translate
                    class="pull-left float-left d-flex align-items-center flex-wrap"
                  >
                    <span
                      class="venture__company-header mr-4 d-block d-lg-inline-block"
                      translate
                    >
                      {{
                        ([orgAttributes] | fieldLabel : 'Challenges') ||
                          'Challenges'
                      }}</span
                    >

                    <!-- Create Challenge btn -->
                    <ng-container *ngIf="!isCreating && (isOwner || isTeam)">
                      <button
                        appRequireLogin
                        appDebounceClick
                        (debounceClick)="createChallengeFromThisOrg(entity?.id)"
                        (click)="$event.stopPropagation()"
                        class="btn btn-primary form-section__btn mr-1 mr-md-4 ml-0"
                      >
                        {{ 'UI.OrganizationEdit.CreateChallenge' | translate }}
                      </button>
                    </ng-container>
                  </div>

                  <i
                    class="fas float-right pull-right venture__company-header"
                    [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                  ></i>
                </button>
              </ng-template>

              <ng-template ngbPanelContent>
                <div
                  class="venture__body widget__card--background partner-orgs-section"
                  [ngClass]="{
                    'partner-orgs-list':
                      !isShownAsChallenge && !orgPartner.editting && !isCreating
                  }"
                >
                  <app-form-section
                    #orgPartner
                    [index]="orgSectionIndex.Partner"
                    [canSave]="isValidSection(orgSectionIndex.Partner)"
                    [showEditButton]="!isCreating && editable"
                    [saveSectionCallback]="saveSection.bind(this)"
                    (cancelSection)="partnerOrgs.reset(); cancelSection($event)"
                    (editIndexChange)="markEditingSectionIndex($event)"
                  >
                    <app-partner-oganizations
                      #partnerOrgs
                      [organisations]="orgAsChallenges"
                      [ignoreCompany]="entity"
                      [editing]="isCreating || (isOwner && orgPartner.editting)"
                      [userId]="profile?.id"
                      [organizationType]="organizationType.Challenge"
                      (notifyOrganisationPayload)="updateOnlyChallenges($event)"
                      [advancedSetting]="isOwner && !isShownAsChallenge"
                      [showVenturesCtrl]="false"
                    ></app-partner-oganizations>
                  </app-form-section>
                </div>
              </ng-template>
            </ngb-panel>
          </ng-container>
        </ng-container>

        <!-- Ventures -->
        <ngb-panel
          *ngIf="!isCreating && entity"
          cardClass="venture__body venture__accordion ignore-overflow"
          id="ventures-panel"
        >
          <!-- Header -->
          <ng-template ngbPanelHeader let-opened="opened">
            <button class="btn panel__header" ngbPanelToggle type="button">
              <div class="w-100 pull-left float-left d-flex align-items-center">
                <div class="d-flex align-items-center flex-wrap">
                  <span class="venture__company-header mr-2" translate>
                    Ventures
                  </span>

                  <!-- Tooltip -->
                  <i
                    class="fas fa-info-circle widget__color mr-2 mr-sm-4"
                    (click)="$event.stopPropagation()"
                    triggers="click"
                    [ngbTooltip]="tooltipContent"
                    appTooltip
                    #t="ngbTooltip"
                    [toolTipControl]="t"
                  ></i>

                  <app-switch-view-control
                    [(isGridView)]="isVentureGridView"
                    (isGridViewChange)="orgVentures?.cancel()"
                    (showExpandTable)="isSharedVentureExpanded = true"
                  >
                  </app-switch-view-control>

                  <div class="icon-control mr-2">
                    <i
                      class="fas fa-filter"
                      [class.selected]="isVentureFilterActive"
                      (click)="toggleVentureFilter($event)"
                    ></i>
                  </div>

                  <ng-template #tooltipContent>
                    <span
                      class="tooltip-link"
                      (click)="$event.stopPropagation()"
                      [innerHtml]="
                        'Org-Ventures-Tooltip'
                          | translate
                          | stringReplacement
                            : {
                                '{Venture}':
                                  ventureBaseUrl
                                  | linkWrapper : ('Venture' | translate)
                              }
                      "
                    >
                    </span>
                  </ng-template>

                  <ng-container
                    *ngIf="
                      !isCreating &&
                      isShownAsChallenge &&
                      isSubmissionPhase(entity)
                    "
                  >
                    <div
                      class="d-flex align-items-center justity-content-center"
                      [ngbTooltip]="
                        getReasonWhyCannotSubmitVentureToCall(entity)
                          | translate
                      "
                      [disableTooltip]="canSubmitVentureToCall(entity)"
                      (click)="$event.preventDefault()"
                    >
                      <div
                        class="d-flex align-items-md-center mt-2 mt-xl-0"
                        [class.disabled-input]="!canSubmitVentureToCall(entity)"
                      >
                        <app-copy-text
                          [isLoginRequired]="true"
                          [textToCopy]="submitVentureToOrganizationUrl"
                          [copyWithCondition]="true"
                          [isUrlCopied]="isSubmitVentureToOrganizationUrlCopied"
                          (copyWithConditionClicked)="
                            checkCanCopyUrlSubmitVentureToOrg()
                          "
                        ></app-copy-text>

                        <small class="hint-text pl-1">{{
                          'UI.ORG.Submit-Venture-Hint' | translate
                        }}</small>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
              <i
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
          </ng-template>

          <!-- Content -->
          <ng-template ngbPanelContent>
            <div
              class="venture__body widget__card--background ventures-section"
            >
              <!-- Submit Venture btn -->
              <ng-container
                *ngIf="
                  !isCreating && isShownAsChallenge && isSubmissionPhase(entity)
                "
              >
                <div
                  class="d-flex flex-column flex-sm-row align-items-stretch align-items-sm-center justity-content-center"
                  [ngbTooltip]="
                    getReasonWhyCannotSubmitVentureToCall(entity) | translate
                  "
                  [disableTooltip]="canSubmitVentureToCall(entity)"
                  (click)="$event.preventDefault()"
                >
                  <button
                    appRequireLogin
                    appDebounceClick
                    (debounceClick)="submitVentureToOrg()"
                    (click)="$event.stopPropagation()"
                    [disabled]="!canSubmitVentureToCall(entity)"
                    class="btn btn-primary form-section__btn mr-1 mr-md-2 ml-0 mb-3"
                  >
                    {{ 'UI.Common.SubmitANewProjectIdea' | translate }}
                  </button>

                  <app-submit-ventures
                    appRequireLogin
                    modalHeader="UI.Common.SubmitProjectIdeas"
                    [shouldOpenModal]="isLogin"
                    [challengeId]="entity.id"
                    [globalOrgId]="globalOrgId"
                    [disabled]="!canSubmitVentureToCall(entity)"
                    [className]="'mb-3'"
                    (modalClosed)="
                      $event && sharedVenturesComp?.refreshVentures()
                    "
                  ></app-submit-ventures>
                </div>
              </ng-container>
              <ng-container *ngIf="!isVentureLoading; else loadingVenture">
                <app-form-section
                  #orgVentures
                  [index]="orgSectionIndex.Ventures"
                  [canSave]="true"
                  [showEditButton]="
                    isVentureGridView ? editable && ventureLength > 0 : false
                  "
                  [saveSectionCallback]="saveSubmissionStatuses.bind(this)"
                  (cancelSection)="cancelSection($event)"
                  (editIndexChange)="markEditingSectionIndex($event)"
                >
                  <app-shared-ventures
                    (confirmSaving)="orgVentures.save(false)"
                    (ventureCount)="getVentureCount($event)"
                    [(isGridView)]="isVentureGridView"
                    [(isTableExpanded)]="isSharedVentureExpanded"
                    [(showFilter)]="isVentureFilterActive"
                    [allowPaginationWarning]="true"
                    [allowUpdateSubmissionStatus]="
                      isVentureGridView
                        ? editable && orgVentures.editting
                        : editable
                    "
                    [cancelSection$]="cancelSection$"
                    [httpService]="orgHttpService"
                    [isAdmin]="isMyOrganisation(entity.initiators)"
                    [isParentEntityOwner]="isOwner"
                    [isShowAsChallenge]="isShownAsChallenge"
                    [paginationSettingPlace]="
                      isShownAsChallenge
                        ? PaginationSettingPlace.VentureListInChallengeDetail
                        : PaginationSettingPlace.VentureListInOrganizationDetail
                    "
                    [parentEntityId]="entity?.id"
                    [parentEntityName]="
                      isShownAsChallenge
                        ? EntityName.Challenge
                        : EntityName.Organization
                    "
                    [globalOrgId]="globalOrgId"
                  >
                  </app-shared-ventures>
                </app-form-section>
              </ng-container>

              <ng-template #loadingVenture>
                <div class="text-center">
                  <i class="fas fa-spinner fa-spin"></i>
                </div>
              </ng-template>
            </div>
          </ng-template>
        </ngb-panel>

        <!-- Events -->
        <ngb-panel
          *ngIf="!isCreating && entity"
          cardClass="venture__body venture__accordion"
          id="events-panel"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button class="btn panel__header" ngbPanelToggle type="button">
              <div translate class="pull-left float-left">
                <span
                  class="venture__company-header mr-5 d-block d-lg-inline-block"
                  translate
                  >Events</span
                >
              </div>
              <i
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <div class="venture__body widget__card--background events-section">
              <app-shared-events
                [isChallenge]="isShownAsChallenge"
                [entityId]="entity?.id"
                [httpService]="
                  isShownAsChallenge ? challengeHttpService : orgHttpService
                "
                [pageSize]="2"
              >
              </app-shared-events>
            </div>
          </ng-template>
        </ngb-panel>

        <!-- Juror & Rating -->
        <ngb-panel
          *ngIf="!isCreating && entity?.showAsChallenge"
          cardClass="venture__body venture__accordion"
          id="{{ SectionId.JuryPanel }}"
          (ngxVisibility)="
            scrollingService.scrollToFragment(SectionId.JuryPanel)
          "
        >
          <ng-template ngbPanelHeader let-opened="opened"
            ><button class="btn panel__header" ngbPanelToggle type="button">
              <div translate class="pull-left float-left">
                <span
                  class="venture__company-header mr-5 d-block d-lg-inline-block"
                  translate
                  >UI.Challenge.JuryAndRating</span
                >
              </div>
              <i
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <div class="venture__body widget__card--background events-section">
              <app-jury-section
                [profile]="profile"
                [challenge]="entity"
                [showEditButton]="!isCreating && editable"
                (acceptedAsJuror)="sharedVenturesComp?.refreshVentures()"
              ></app-jury-section>
            </div>
          </ng-template>
        </ngb-panel>

        <!-- People -->
        <ngb-panel
          cardClass="venture__body venture__accordion"
          id="people-panel"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button class="btn panel__header" ngbPanelToggle type="button">
              <div translate class="pull-left float-left">
                <span
                  [id]="orgSectionIndex.Stakeholders"
                  class="venture__company-header mr-5 d-block d-lg-inline-block"
                  translate
                  >{{ 'Initiator' | translate }} &
                  {{ 'Network' | translate }}</span
                >
              </div>

              <div
                *ngIf="innerWidth > MediaBreakpoint.xl"
                class="d-flex align-items-center gap-4"
              >
                <app-partner-organization-select
                  *ngIf="!entity?.showAsChallenge"
                  [leadOrgId]="entity?.id"
                  [orgOptions]="orgOptions"
                  (partnerOrgChange)="onOrgChange($event)"
                ></app-partner-organization-select>
                <i
                  class="fas float-right pull-right venture__company-header"
                  [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                ></i>
              </div>
              <i
                *ngIf="innerWidth <= MediaBreakpoint.xl"
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
            <app-partner-organization-select
              *ngIf="
                !entity?.showAsChallenge && innerWidth <= MediaBreakpoint.xl
              "
              [leadOrgId]="entity?.id"
              [orgOptions]="orgOptions"
              (partnerOrgChange)="onOrgChange($event)"
            ></app-partner-organization-select>
          </ng-template>

          <ng-template ngbPanelContent>
            <div class="venture__body widget__card--background org-people">
              <div class="row">
                <div class="col col-12 px-md-0">
                  <ng-container *ngIf="!isCreating">
                    <app-search-people-box
                      [searchName]="filteringKeyword"
                      (keyChanged)="onSearchPeople($event)"
                      styleClass="my-2"
                    ></app-search-people-box>

                    <label
                      class="venture__label venture__label--head widget__label"
                      translate
                      >{{
                        ([orgAttributes] | fieldLabel : 'Initiator') ||
                          'Initiator'
                      }}
                    </label>

                    <ng-container
                      *ngIf="initiators?.length > 0; else deactiveUser"
                    >
                      <app-initator-cards
                        id="admin-community"
                        #initiatorsCardsComponent
                        (toItemsChange)="setStakeholders($event)"
                        (itemsRoleUpdate)="
                          isShownAsChallenge
                            ? setInitators($event)
                            : loadCommunity()
                        "
                        (peopleCardClicked)="
                          saveSectionPosition(orgSectionIndex.Stakeholders)
                        "
                        [httpService]="baseHttpService"
                        [items]="initiators"
                        [entityId]="entity?.id"
                        [toItems]="stakeholders"
                        [editable]="false"
                        [enableExtraAction]="isOwner"
                        [ignoreId]="profile?.id"
                        [parentEntityName]="
                          isShownAsChallenge ? EntityName.Challenge : entityName
                        "
                        [parentEntity]="entity"
                        [filteringKeyword]="filteringKeyword"
                        [globalOrgId]="globalOrgId"
                        [allowRemoveMyself]="isShownAsChallenge ? false : true"
                      ></app-initator-cards>
                    </ng-container>
                    <ng-template #deactiveUser>
                      <app-deactive-entity
                        [deactiveShowType]="deactiveShowTypeEnum.Card"
                      ></app-deactive-entity>
                    </ng-template>
                  </ng-container>

                  <div *ngIf="entity?.canInvite" class="my-4 pb-3">
                    <app-invitation-by-email
                      [baseHttpService]="baseHttpService"
                      [ccEmail]="(profile$ | async)?.email"
                      [entityId]="entity?.id"
                      [inviteTokenType]="TokenTypes.InviteChallenge"
                      [messageBody]="'UI.Invitation.Mail-content--Body-For-Org'"
                      [senderName]="
                        profile?.firstName + ' ' + profile?.lastName
                      "
                      [senderSignature]="
                        'UI.Invitation.Signature'
                          | translate
                          | stringReplacement
                            : {
                                '{name}':
                                  profile?.firstName + ' ' + profile?.lastName,
                                '{portalName}': portalName
                              }
                      "
                      [orgName]="!entity.showAsChallenge && entity.orgName"
                    ></app-invitation-by-email>
                  </div>

                  <div
                    class="d-flex flex-column flex-md-row align-items-start align-items-md-center"
                  >
                    <label
                      class="venture__label venture__label--head widget__label mr-4 flex-shrink-0"
                      translate
                    >
                      {{
                        ([orgAttributes] | fieldLabel : 'Stakeholders') ||
                          'Network'
                      }}</label
                    >

                    <ng-container
                      *ngIf="
                        !isCreating &&
                        isTeam &&
                        (isShownAsChallenge || selectedOrgId === entity.id)
                      "
                    >
                      <button
                        appLeaveEntity
                        [entityId]="entity.id"
                        [entityName]="
                          isShownAsChallenge
                            ? EntityName.Challenge
                            : EntityName.Organization
                        "
                        [(isLeaving)]="isLeavingEntity"
                        [disabled]="isLeavingEntity"
                        (afterLeaved)="afterLeaveEntity($event)"
                        class="btn btn-outline-primary form-section__btn m-0 mt-2 mt-md-0 flex-shrink-0"
                      >
                        <i class="fas fa-user-times"></i>
                        <span
                          class="ml-2"
                          [innerHTML]="
                            'UI.Common.LeaveThis'
                              | translate
                              | stringReplacement
                                : {
                                    '{entity}': isShownAsChallenge
                                      ? (EntityName.Challenge | translate)
                                      : (EntityName.Organization | translate)
                                  }
                          "
                        >
                        </span>
                      </button>

                      <div
                        class="ml-md-2 ml-0 font-weight-bold leave-org-name d-flex align-items-center"
                        *ngIf="!entity.showAsChallenge"
                      >
                        <span class="truncate-line-1"
                          >({{ entity.orgName }}</span
                        >
                        <span>)</span>
                      </div>
                    </ng-container>
                  </div>

                  <app-stakeholder-cards
                    #stakeholderCardsComponent
                    (itemsChange)="updateStakeholder($event)"
                    (peopleCardClicked)="
                      saveSectionPosition(orgSectionIndex.Stakeholders)
                    "
                    (toItemsChange)="setInitators($event)"
                    (itemsRoleUpdate)="setStakeholders($event)"
                    (peopleAdded)="onPeopleAdded()"
                    [httpService]="baseHttpService"
                    [items]="stakeholders"
                    [entityId]="entity?.id"
                    [toItems]="initiators"
                    [editable]="editable"
                    [enableExtraAction]="isOwner"
                    [ignoreId]="profile?.id"
                    [excludedPeopleIds]="ignoreSearchPeopleIds"
                    [parentEntityName]="
                      isShownAsChallenge ? EntityName.Challenge : entityName
                    "
                    [parentEntity]="entity"
                    [filteringKeyword]="filteringKeyword"
                    [globalOrgId]="globalOrgId"
                  ></app-stakeholder-cards>
                </div>
              </div>
            </div>
          </ng-template>
        </ngb-panel>

        <!-- Challenge Sequence -->
        <ng-container *ngIf="isShownAsChallenge">
          <!-- Partner Organizations & Networks -->
          <ngb-panel
            cardClass="venture__body venture__accordion ignore-overflow"
            id="partner-org-panel"
          >
            <ng-template ngbPanelHeader let-opened="opened">
              <button class="btn panel__header" ngbPanelToggle type="button">
                <div translate class="pull-left float-left">
                  <span
                    class="venture__company-header d-block d-lg-inline-block mr-5"
                    translate
                  >
                    {{
                      ([orgAttributes] | fieldLabel : 'PartnerOrganizations') ||
                        'UI.ORG.Partner-organizations'
                    }}</span
                  >
                </div>
                <i
                  class="fas float-right pull-right venture__company-header"
                  [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                ></i>
              </button>
            </ng-template>
            <ng-template ngbPanelContent>
              <ng-container *ngTemplateOutlet="parterOrganizationsPanelContent">
              </ng-container>
            </ng-template>
          </ngb-panel>
        </ng-container>

        <!-- Dashboard Timeline -->
        <ngb-panel
          *ngIf="isShownAsChallenge"
          cardClass="venture__body venture__accordion ignore-overflow"
          id="chart-panel"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button class="btn panel__header" ngbPanelToggle type="button">
              <div
                translate
                class="pull-left float-left d-flex align-items-center justify-content-start"
              >
                <span class="venture__company-header d-block d-lg-inline-block">
                  {{ 'Dashboard' | translate }}
                </span>

                <button
                  *ngIf="isOwner || isTeam"
                  type="button"
                  class="btn btn-outline-primary form-section__btn"
                  appRequireLogin
                  appDebounceClick
                  (debounceClick)="reportingChart.exportData(reportName)"
                >
                  <ng-container *ngIf="isExportLoading">
                    <i class="fas fa-spinner fa-spin"></i>
                  </ng-container>
                  <i *ngIf="!isExportLoading" class="fas fa-file-export"></i>
                  <span>
                    {{ 'UI.Common.Export' | translate }}
                  </span>
                </button>
              </div>

              <i
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <div
              class="venture__body widget__card--background partner-orgs-section"
            >
              <app-reporting-chart
                #reportingChart
                [entity_id]="entity?.id"
                (isExporting)="onExportLoading($event)"
              ></app-reporting-chart>
            </div>
          </ng-template>
        </ngb-panel>

        <!-- Expertise -->
        <ngb-panel
          cardClass="venture__body venture__accordion"
          id="expertise-panel"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button class="btn panel__header" ngbPanelToggle type="button">
              <div class="pull-left float-left">
                <span
                  class="venture__company-header mr-5 d-block d-lg-inline-block"
                  >{{ 'UI.Organization.Expertises' | translate }}</span
                >
              </div>
              <i
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <div
              class="venture__body widget__card--background expertise-section"
            >
              <app-form-section
                #orgExpertiseAndKeyOffering
                [index]="orgSectionIndex.Expertise"
                [canSave]="isValidSection(orgSectionIndex.Expertise)"
                [showEditButton]="!isCreating && editable"
                [saveSectionCallback]="saveSection.bind(this)"
                (cancelSection)="cancelSection($event)"
                (editIndexChange)="markEditingSectionIndex($event)"
              >
                <div class="row">
                  <div class="col col-12">
                    <ng-container
                      *ngIf="
                        !isCreating && !orgExpertiseAndKeyOffering.editting;
                        else editExpertises
                      "
                    >
                      <div class="org-tag-cloud">
                        <app-tagcloud
                          [data]="entity?.expertisesTag"
                        ></app-tagcloud>
                      </div>
                    </ng-container>

                    <ng-template #editExpertises>
                      <app-form-field-custom
                        [formInput]="form"
                        [editable]="
                          isCreating ||
                          (editable && orgExpertiseAndKeyOffering.editting)
                        "
                        [type]="allType.MultiString"
                        [showLabel]="true"
                        [labelClass]="
                          'venture__label venture__label--head widget__label'
                        "
                        [options]="{
                          placeholder: offerringPlaceholder | translate
                        }"
                        [attributeDescription]="
                          entityMetaInfo | fieldOption : 'Expertises'
                        "
                      >
                      </app-form-field-custom>
                    </ng-template>
                  </div>
                </div>
                <div class="row">
                  <div class="col col-12 mt-3">
                    <app-form-field-custom
                      [formInput]="form"
                      [editable]="
                        isCreating ||
                        (editable && orgExpertiseAndKeyOffering.editting)
                      "
                      [type]="allType.MultiString"
                      [showLabel]="true"
                      [labelClass]="
                        'venture__label venture__label--head widget__label'
                      "
                      [options]="{
                        placeholder: offerringPlaceholder | translate
                      }"
                      [attributeDescription]="
                        entityMetaInfo | fieldOption : 'KeyOfferings'
                      "
                    >
                    </app-form-field-custom>
                  </div>
                </div>
              </app-form-section>
            </div>
          </ng-template>
        </ngb-panel>

        <!-- Location -->
        <ngb-panel
          cardClass="venture__body venture__accordion"
          id="location-panel"
        >
          <ng-template ngbPanelHeader let-opened="opened">
            <button class="btn panel__header" ngbPanelToggle type="button">
              <div translate class="pull-left float-left">
                <span
                  class="venture__company-header mr-5 d-block d-lg-inline-block"
                  translate
                  >Location</span
                >
              </div>
              <i
                class="fas float-right pull-right venture__company-header"
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
              ></i>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <div
              class="venture__body widget__card--background location-section"
            >
              <app-form-section
                #orgLocation
                [index]="orgSectionIndex.Location"
                [canSave]="isValidSection(orgSectionIndex.Location)"
                [showEditButton]="!isCreating && editable"
                [saveSectionCallback]="saveSection.bind(this)"
                (cancelSection)="cancelSection($event)"
                (editIndexChange)="markEditingSectionIndex($event)"
              >
                <div class="row">
                  <div class="col-12">
                    <app-form-field-custom
                      [formInput]="form"
                      [editable]="
                        isCreating || (editable && orgLocation.editting)
                      "
                      [labelClass]="'venture__label--head'"
                      [type]="allType.Map"
                      [hideLink]="true"
                      [attributeDescription]="
                        entityMetaInfo | fieldOption : 'Location'
                      "
                      [options]="{
                        placeholder:
                          'ORGANIZATION_DETAILS-placeholder-location'
                          | translate
                      }"
                    >
                    </app-form-field-custom>
                  </div>
                </div>
              </app-form-section>
            </div>
          </ng-template>
        </ngb-panel>

        <!-- Advanced setting -->
        <ngb-panel
          *ngIf="isShownAsChallenge && (isCreating || isOwner)"
          cardClass="venture__body venture__accordion"
          id="advanced-setting-panel"
        >
          <ng-template let-opened="opened" ngbPanelHeader>
            <button class="btn panel__header" ngbPanelToggle type="button">
              <div class="pull-left float-left" translate>
                <span
                  class="venture__company-header mr-5 d-block d-lg-inline-block"
                  translate
                  >Settings</span
                >
              </div>
              <i
                [ngClass]="opened ? 'fa-caret-up' : 'fa-caret-down'"
                class="fas float-right pull-right venture__company-header"
              ></i>
            </button>
          </ng-template>

          <ng-template ngbPanelContent>
            <div
              class="venture__body widget__card--background location-section"
            >
              <app-form-section>
                <div class="row">
                  <div class="col-12 my-2">
                    <div class="venture__label widget__label mb-3">
                      {{ getEntityMetaInfo() | fieldLabel : 'IsPrivate' }}
                    </div>
                    <div [formGroup]="form">
                      <div class="organization__radio-item">
                        <input
                          appRequireLogin
                          (change)="changeOrgPrivacyImmediately()"
                          [value]="false"
                          class="organization__radio"
                          formControlName="IsPrivate"
                          id="isPublic"
                          name="IsPrivate"
                          type="radio"
                        />
                        <label
                          class="radio-info d-flex flex-column"
                          for="isPublic"
                        >
                          <span class="radio-text">
                            <i class="fas fa-globe-europe"></i>
                            {{ 'Public' | translate }}
                          </span>
                          <span
                            [innerHtml]="
                              'UI.ORG-DETAIL.Public-org-hint'
                                | translate
                                | stringReplacement
                                  : {
                                      '{portalName}':
                                        portalUrl | linkWrapper : portalName
                                    }
                            "
                            class="radio-hint"
                          ></span>
                        </label>
                      </div>
                      <div class="organization__radio-item">
                        <input
                          appRequireLogin
                          (change)="changeOrgPrivacyImmediately()"
                          [value]="true"
                          class="organization__radio"
                          formControlName="IsPrivate"
                          id="isPrivate"
                          name="IsPrivate"
                          type="radio"
                        />
                        <label
                          class="radio-info d-flex flex-column"
                          for="isPrivate"
                        >
                          <span class="radio-text">
                            <i class="fas fa-lock"></i>
                            {{ 'Private' | translate }}
                          </span>
                          <span class="radio-hint">
                            {{ 'UI.ORG-DETAIL.Private-org-hint' | translate }}
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12 my-2">
                    <div class="venture__label widget__label mb-3">
                      {{ getEntityMetaInfo() | fieldLabel : 'IsAllowToJoin' }}
                    </div>

                    <app-toggle-button-slider
                      appRequireLogin
                      (statusChange)="onAdvancedSettingChange($event)"
                      [settings]="{
                        activeText: 'Yes' | translate,
                        deactiveText: 'No' | translate
                      }"
                      [status]="allowToJoin"
                    ></app-toggle-button-slider>
                  </div>
                </div>

                <div>
                  <div class="venture__label widget__label mb-3">
                    Select who can send messages to the participants
                  </div>
                  <app-radio-selection
                    [items]="
                      getFieldOptions('SendMessageRights').choice.selections
                    "
                    [controlName]="'SendMessageRights'"
                    [currentIndex]="challengeMessageRightSelectedIndex"
                    [editable]="true"
                    (selectItem)="onMessageRightChange($event)"
                  >
                  </app-radio-selection>
                </div>
              </app-form-section>
            </div>
          </ng-template>
        </ngb-panel>

        <!-- Reused Template -->
        <ng-template #parterOrganizationsPanelContent>
          <div
            class="venture__body widget__card--background partner-orgs-section"
            [ngClass]="{
              'partner-orgs-list':
                !isShownAsChallenge &&
                !orgPartner.editting &&
                !isCreating &&
                isPartnerOrgGridView
            }"
          >
            <app-form-section
              #orgPartner
              [index]="orgSectionIndex.Partner"
              [canSave]="isValidSection(orgSectionIndex.Partner)"
              [showEditButton]="!isCreating && editable"
              [saveSectionCallback]="saveSection.bind(this)"
              (cancelSection)="partnerOrgs.reset(); cancelSection($event)"
              (editIndexChange)="markEditingSectionIndex($event)"
            >
              <app-partner-oganizations
                #partnerOrgs
                [organisations]="orgAsPartners"
                [ignoreCompany]="entity"
                [editing]="isCreating || (isOwner && orgPartner.editting)"
                [userId]="profile?.id"
                [organizationType]="organizationType.Organization"
                [showVenturesCtrl]="false"
                [parentEntity]="entity"
                [(isGridView)]="isPartnerOrgGridView"
                [(isTableExpanded)]="isPartnerOrgTableExpanded"
                [isOwner]="isOwner"
                (notifyOrganisationPayload)="updateOnlyPartners($event)"
                [advancedSetting]="isOwner"
              ></app-partner-oganizations>
            </app-form-section>
          </div>
        </ng-template>
      </ngb-accordion>

      <!-- Testify -->
      <div
        *ngIf="isCreating"
        class="card venture__body venture__accordion testify-section"
      >
        <app-form-section
          #orgTestify
          [index]="orgSectionIndex.Testify"
          [canSave]="isValidSection(orgSectionIndex.Testify)"
          [showEditButton]="false"
          [saveSectionCallback]="saveSection.bind(this)"
          (cancelSection)="cancelSection($event)"
          (editIndexChange)="markEditingSectionIndex($event)"
        >
          <div class="row">
            <div class="col col-12">
              <app-form-field-custom
                [formInput]="form"
                [labelClass]="'venture__label--head'"
                [editable]="isCreating"
                [attributeDescription]="
                  entityMetaInfo | fieldOption : 'IsTestifiedAuthorization'
                "
              ></app-form-field-custom>
            </div>
          </div>
        </app-form-section>
        <div
          *ngIf="form.get('IsTestifiedAuthorization')?.errors"
          class="alert alert-danger"
        >
          {{ 'TestifyError' | translate }}
        </div>
      </div>

      <!-- Form error -->
      <div
        *ngIf="(form.errors || form.invalid) && form.touched && isSubmitted"
        class="venture__footer-alert alert alert-danger"
        translate
      >
        {{ errorMessage[formErrorKey] || 'Please check the error above' }}
      </div>

      <!-- Submit btn -->
      <div *ngIf="isCreating" class="venture__btn-wrapper">
        <button
          appRequireSession
          (credentialClick)="onSubmit()"
          class="btn btn-primary venture__btn-submit"
          [disabled]="
            isSubmitting || isSubmitted || !(form?.touched || form?.dirty)
          "
        >
          <ng-container *ngIf="isSubmitting">
            <i class="fas fa-spinner fa-spin"></i>
          </ng-container>
          <span translate>{{ isCreating ? 'Create' : 'Save' }}</span>
        </button>
      </div>
    </div>
  </form>
</ng-container>

<app-people-modal
  *ngIf="showPeopleModal"
  [entityId]="selectedEntityId"
  [entityTitle]="entity?.orgName"
  modalTitle="Followers"
  [httpService]="orgHttpService"
  (dismissModal)="showPeopleModal = false"
></app-people-modal>

<app-pending-people-modal
  *ngIf="showPendingPeopleModal"
  [entityId]="selectedEntityId"
  [httpService]="orgHttpService"
  (dismissModal)="showPendingPeopleModal = false"
  (notifyRequestChange)="onPendingRequestChange()"
>
</app-pending-people-modal>

<app-likes-modal
  *ngIf="showLikesModal"
  [entityId]="selectedEntityId"
  [entityTitle]="entity?.orgName"
  [modalTitle]="'UI.Likes.Organization' | translate"
  [httpService]="baseHttpService"
  (dismissModal)="showLikesModal = false"
></app-likes-modal>
