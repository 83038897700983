import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrgsSearchMode } from '@shared/enums/org-search-mode.enum';
import { LeadCompany } from '@shared/interfaces/lead-company.interface';
import { OrganizationInterface } from '@shared/interfaces/organization.interface';
import { OrganizationHttpService } from '@src/app/core/http/organization-http.service';
import { CentralConfigService } from '@src/app/core/services/central-config.service';
import { GlobalFilterStoredService } from '@src/app/core/services/global-filter-stored.service';
import { OrganizationType } from '@src/app/shared/enums/organization.enum';

@Component({
  selector: 'app-partner-oganizations',
  templateUrl: './partner-oganizations.component.html',
})
export class PartnerOganizationsComponent implements OnChanges {
  @Input() ignoreCompany: LeadCompany;
  @Input() editing: boolean;
  @Input() userId: number;
  @Input() organisations: LeadCompany[] = [];
  @Input() organizationType: OrganizationType = OrganizationType.All;
  @Input() showVenturesCtrl = true;
  @Input() advancedSetting: boolean;
  @Input() parentEntity;
  @Input() isOwner;
  @Input() isGridView = true;
  @Input() isTableExpanded = false;

  @Output() notifyOrganisationPayload = new EventEmitter<LeadCompany[]>();
  @Output() isGridViewChange = new EventEmitter<boolean>();
  @Output() isTableExpandedChange = new EventEmitter<boolean>();

  previousItems: LeadCompany[] = [];
  orgsSearchMode: OrgsSearchMode = OrgsSearchMode.ForSubmittingAll;
  organizationTypeEnum = OrganizationType;
  items = [];
  isLoading = false;
  pageSize = 4;
  pageIndex = 1;
  collectionSize;
  isLoadingTable: boolean;
  keyword = '';

  @ViewChild('modalRef') modalRef: ElementRef;

  constructor(
    public orgHttpSerivce: OrganizationHttpService,
    private modalService: NgbModal,
    private centralConfig: CentralConfigService,
    private filterStoredService: GlobalFilterStoredService
  ) {}

  get ignoreOrgIds(): number[] {
    return this.organisations.map((o) => o.id);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.organisations &&
      !this.compareArray(
        changes.organisations.currentValue,
        changes.organisations.previousValue
      )
    ) {
      if (this.organisations) {
        this.organisations = [...this.organisations];
      } else {
        this.organisations = [];
      }
      this.previousItems = [...this.organisations];
      this.emitOrganisationPayload();
    }

    if (changes.parentEntity) {
      if (this.parentEntity) {
        this.onFilter();
      }
    }

    if (
      changes.isTableExpanded &&
      changes.isTableExpanded.previousValue !== undefined
    ) {
      if (changes.isTableExpanded.currentValue) {
        this.modalService
          .open(this.modalRef, {
            centered: true,
            backdrop: 'static',
            windowClass: 'modal-fullscreen',
          })
          .result.finally(() => {
            this.emitTableExpanded(false);
          });

        if (this.isGridView) {
          this.isGridView = false;
          this.isGridViewChange.emit(this.isGridView);
        }
      }
    }
  }

  selectItem(item: OrganizationInterface): void {
    const idx = this.organisations.findIndex((org) => org.id === item.id);

    if (!item || item.id === undefined || idx >= 0) {
      return;
    }

    const leadOrg: LeadCompany = { ...item };
    if (leadOrg.showAsChallenge && leadOrg.showVentures === undefined) {
      leadOrg.showVentures = true;
    }
    this.organisations.push(leadOrg);
    this.emitOrganisationPayload();
  }

  removeItem(item: LeadCompany): void {
    if (!item) {
      return;
    }

    const idx = this.organisations.findIndex((org) => org.id === item.id);
    if (idx < 0) {
      return;
    }

    this.organisations.splice(idx, 1);
    this.emitOrganisationPayload();
  }

  reset(): void {
    if (Array.isArray(this.previousItems)) {
      this.organisations = [...this.previousItems];
      this.emitOrganisationPayload();
    }
  }

  private emitOrganisationPayload(): void {
    this.notifyOrganisationPayload.emit([...this.organisations]);
  }

  compareArray(arrA: LeadCompany[], arrB: LeadCompany[]): boolean {
    if (JSON.stringify(arrA) === JSON.stringify(arrB)) {
      return true;
    }
    return false;
  }

  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.onFilter();
  }
  onPageChange(pageNumber: number): void {
    this.pageIndex = pageNumber;
    this.onFilter();
  }

  emitTableExpanded(value) {
    this.isTableExpanded = value;
    this.isTableExpandedChange.emit(this.isTableExpanded);
  }

  onResetSearchText() {
    this.keyword = '';
  }

  async onFilter() {
    this.isLoading = true;
    const activeInnovationSpace = this.centralConfig.innovationSpaceId;
    const filterCriteria = await this.filterStoredService.getFilterCriteria();
    const globalOrgId = filterCriteria?.organizationIds?.[0];
    const orgId = activeInnovationSpace || globalOrgId;

    this.orgHttpSerivce
      .getPartnerOrgs({
        OrgId: this.parentEntity.id,
        PageIndex: this.pageIndex,
        PageSize: this.pageSize,
        ShowAsChallenge: this.parentEntity.showAsChallenge,
        Keyword: this.keyword,
        organizationId: orgId ?? '',
      })
      .subscribe((data) => {
        this.isLoading = false;
        this.items = data.items;
        this.pageIndex = data.pageIndex;
        this.pageSize = data.pageSize;
        this.collectionSize = data.rowCount;
      });
  }
}
